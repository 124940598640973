import React from 'react';
import { Container } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import mapSVG from '~/icons/map-marker.svg';
import timeSVG from '~/icons/time.svg';
import hashtagSVG from '~/icons/hashtag.svg';
import personSVG from '~/icons/person.svg';

const JobOfferView = ({ content }) => {
  const fixWebAddress = (url) => {
    let newUrl = url;
    newUrl = newUrl.trim().replace(/\s/g, '');

    if (/^(:\/\/)/.test(newUrl)) {
      return `https${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `https://${newUrl}`;
    }

    return newUrl;
  };

  const stripWebAddress = (url) => {
    return url.replace(/(^\w+:|^)\/\//, '').replace('www.', '');
  };

  return (
    <Container className="jobOfferView">
      <h1 className="documentFirstHeading">{content.title}</h1>
      <img
        className="banner full-width"
        src={content.image.download}
        alt={content.image.title}
      ></img>
      <div className="top-info">
        <div>
          <Icon name={mapSVG} size="26px" />
          <span>{content.job_offer_location_city_name}</span>
        </div>
        <div>
          {content.job_offer_categories.length > 0 && (
            <>
              <Icon name={hashtagSVG} size="26px" />
              <span>{content.job_offer_categories?.[0].title}</span>
            </>
          )}
        </div>

        <div>
          <Icon name={timeSVG} size="26px" />
          <span>
            {`${content.time_amount} Std./${content.time_units.title}`}
          </span>
        </div>
      </div>
      <div className="description">
        <p>{content.job_offer_description}</p>
      </div>
      <h2 className="section-heading">Was Sie mitbringen sollten</h2>
      <ul>
        {content.requirement_one && <li>{content.requirement_one}</li>}
        {content.requirement_two && <li>{content.requirement_two}</li>}
        {content.requirement_three && <li>{content.requirement_three}</li>}
        {content.requirement_four && <li>{content.requirement_four}</li>}
      </ul>
      <h2 className="section-heading">Unser Angebot</h2>
      <ul>
        {content.benefit_one && <li>{content.benefit_one}</li>}
        {content.benefit_two && <li>{content.benefit_two}</li>}
        {content.benefit_three && <li>{content.benefit_three}</li>}
        {content.benefit_four && <li>{content.benefit_four}</li>}
      </ul>
      <div className="call-to-action full-width">
        <Container>
          <h2 className="section-heading">
            Sie möchten sich bei uns engagieren?
          </h2>
          <p>
            Dann kontaktieren Sie uns und wir vereinbaren einen Termin zum
            persönlichen Kennenlernen. <br />
            Ihr*e Ansprechpartner*in freut sich auf Sie!
          </p>
          <a
            href={`mailto:${content.email}`}
            className="ui button branded blue"
          >
            Jetzt online Kontakt aufnehmen!
          </a>
        </Container>
      </div>
      <h2 className="section-heading">Kontaktdaten</h2>

      <div className="contact">
        <div className="person">
          <Icon name={personSVG} size="20px" />
          <div>
            <p className="name">{content.name}</p>
            <p className="phone">{content.phone}</p>
            <p className="email">
              <a href={`mailto:${content.email}`}>{content.email}</a>
            </p>
            {content.website && (
              <p className="website">
                <a href={fixWebAddress(content.website)}>
                  {stripWebAddress(content.website)}
                </a>
              </p>
            )}
          </div>
        </div>
        <div className="address">
          <Icon name={mapSVG} size="20px" />
          <div>
            <p className="bussiness-name">{content.bussiness_name}</p>
            <p className="address">{content.bussiness_address}</p>
            <p className="city">{content.bussiness_city}</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default JobOfferView;
