/**
 * SprachmodulView view component.
 * @module components/theme/View/SprachmodulView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Container, Image, Grid, Icon } from 'semantic-ui-react';
import ReactGA from 'react-ga4';

import ListingView from './ListingView';
import SprachmodulList from './Listings/SprachmodulList';
import SprachmodulContent from './Listings/SprachmodulContent';
import NavigationPortlet from './Portlets/Navigation';
import MediaPortlet from './Portlets/Media';

import licenseSVG from '../../../public/default-license-portlet.svg';

/**
 * Listing view component function.
 * @function SprachmodulView
 * @param {object} props Content
 * @returns {string} The component
 */
const SprachmodulView = (props) => (
  <Container>
    <Grid stackable>
      <Grid.Column mobile={12} tablet={12} computer={8}>
        <ListingView
          {...props}
          listingView={SprachmodulList}
          contentView={SprachmodulContent}
        />
        <div className="sprachmodul-button">
          {props.content.pdf_file && (
            <Button
              className="branded"
              primary
              icon
              size="large"
              labelPosition="left"
              href={props.content.pdf_file.download}
              as="a"
              onClick={() =>
                ReactGA.event({
                  category: 'Download',
                  action: 'file_download',
                  label: props.content.pdf_file.download,
                })
              }
            >
              <Icon className="fontawesome" name="file pdf outline" />
              <Button.Content>Sprachmodul herunterladen</Button.Content>
            </Button>
          )}
        </div>
      </Grid.Column>
      <Grid.Column width={4} className="portlets-column">
        <NavigationPortlet content={props.content} />
        {props.content.portlet_image_1 && (
          <aside className="image-portlet">
            <a
              href={props.content.portlet_link_1}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={props.content.portlet_image_1.scales.preview.download}
              />
            </a>
          </aside>
        )}
        <aside className="license-portlet">
          <Image src={licenseSVG} />
          <div className="inner">
            <p>
              Die Inhalte im vhs-Ehrenamtsportal unterliegen ― sofern nicht
              anders gekennzeichnet ― der Lizenz <strong>CC BY SA 4.0.</strong>
            </p>
            <Link to="/oer">
              Mehr erfahren{' '}
              <Icon name="chevron right" size="small" className="fontawesome" />
            </Link>
          </div>
        </aside>
        <MediaPortlet content={props.content} />
      </Grid.Column>
    </Grid>
  </Container>
);

SprachmodulView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    pdf_file: PropTypes.object,
    portlet_image_1: PropTypes.object,
    portlet_link_1: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        description: PropTypes.string,
        review_state: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default SprachmodulView;
