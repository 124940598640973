/**
 * AkteureAlbum view component.
 * @module components/theme/View/Listings/AkteureAlbum
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { Button, Card, Icon, Image } from 'semantic-ui-react';

/**
 * AkteureAlbum component class.
 * @function AkteureAlbum
 * @param {array} items Items object.
 * @returns {string} Markup of the component.
 */
const AkteureAlbum = ({ items, clickable, handleShowForm }) => (
  <>
    {items && (
      <Card.Group stackable doubling itemsPerRow={3}>
        {items.map((item) => (
          <Card
            as={clickable ? Link : null}
            to={clickable ? item['@id'] : null}
            title={item.title}
            key={item['@id']}
          >
            <div className="image-wrapper">
              {item.image && (
                <Image
                  src={
                    item.image.width > 0
                      ? item.image.scales.preview.download
                      : item.image.download
                  }
                  alt={item.image_caption ? item.image_caption : item.title}
                />
              )}
            </div>
            <Card.Content>
              <Card.Header content={item.title} textAlign="center" />
              {item.funktion && (
                <Card.Meta content={item.funktion} textAlign="center" />
              )}
              {item.description_akteur_overview ? (
                <Card.Description content={item.description_akteur_overview} />
              ) : (
                item.text && (
                  <Card.Description
                    dangerouslySetInnerHTML={{
                      __html: item.text.data,
                    }}
                  />
                )
              )}
              {item.email && (
                <Card.Content extra>
                  <Button
                    as={Link}
                    to={item['@id']}
                    className="branded"
                    primary
                    icon
                    size="large"
                    style={{ width: '100%' }}
                    labelPosition="left"
                  >
                    <Icon className="fontawesome" name="envelope" />
                    <Button.Content>Kontakt aufnehmen</Button.Content>
                  </Button>
                </Card.Content>
              )}
            </Card.Content>
          </Card>
        ))}
        {/* Workaround while Flexbox 2.0 doesn't implement last row alignment */}
        {[...Array(3)].map((e, i) => (
          <Card key={uniqueId(i)} />
        ))}
      </Card.Group>
    )}
  </>
);
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AkteureAlbum.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.object,
      '@type': PropTypes.string,
    }),
  ).isRequired,
  clickable: PropTypes.bool,
  handleShowForm: PropTypes.func.isRequired,
};

AkteureAlbum.defaultProps = {
  clickable: true,
};

export default AkteureAlbum;
