/**
 * File view component.
 * @module components/theme/View/FileView
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { Container } from 'semantic-ui-react';

/**
 * File view component class.
 * @function FileView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const FileView = ({ content }) => (
  <Container>
    <div className="view-wrapper">
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && <span> &mdash;{` ${content.subtitle}`}</span>}
      </h1>
      {content.description && (
        <p className="description">{content.description}</p>
      )}
      <a
        href={content.file.download}
        onClick={() =>
          ReactGA.event({
            category: 'Download',
            action: 'file_download',
            label: content.file.download,
          })
        }
      >
        {content.file.filename}
      </a>
    </div>
  </Container>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FileView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    file: PropTypes.shape({
      download: PropTypes.string,
      filename: PropTypes.string,
    }),
  }).isRequired,
};

export default FileView;
