import React from 'react';
import DiscourseBody from './DiscourseBody';

const View = () => {
  return (
    <div className="block discourse">
      <div className="full-width">
        <DiscourseBody />
      </div>
    </div>
  );
};

export default View;
