/**
 * FlipCard component.
 * @module components/theme/View/FlipCard
 */

import React, { Component } from 'react';
import ReactCardFlip from 'react-card-flip';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

/**
 * FlipCard class.
 * @class FlipCard
 * @extends Component
 */
export default class FlipCard extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    frontImage: PropTypes.string,
    frontColorClass: PropTypes.string,
    frontText: PropTypes.string,
    backImage: PropTypes.string,
    backColorClass: PropTypes.string,
    backText: PropTypes.string,
    icon: PropTypes.object,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    frontImage: null,
    frontText: '',
    backImage: null,
    backText: '',
    icon: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * On click handler
   * @method onClick
   * @param {object} event Event object.
   * @returns {undefined}
   */
  handleClick(event) {
    event.preventDefault();
    this.setState({ isFlipped: !this.state.isFlipped });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const frontColorClass = this.props.frontColorClass
      ? `${this.props.frontColorClass.token}-front`
      : '';
    const backColorClass = this.props.backColorClass
      ? `${this.props.backColorClass.token}-back`
      : '';
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={this.handleClick}
        onKeyPress={this.handleClick}
        className={`${frontColorClass} ${backColorClass}`}
      >
        <ReactCardFlip isFlipped={this.state.isFlipped}>
          <div key="front">
            {this.props.frontImage ? (
              <Image src={this.props.frontImage} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: this.props.frontText }} />
            )}
            {this.props.icon}
          </div>

          <div key="back">
            {this.props.backImage ? (
              <Image src={this.props.backImage} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: this.props.backText }} />
            )}
            {this.props.icon}
          </div>
        </ReactCardFlip>
      </div>
    );
  }
}
