/**
 * Contact form.
 * @module components/theme/ContactForm/ContactForm
 */

import React, { Component } from 'react';
import { Helmet } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import {
  Container,
  Button,
  Form,
  Input,
  Message,
  Segment,
  TextArea,
  Grid,
} from 'semantic-ui-react';

import { addMessage, emailNotification } from '@plone/volto/actions';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  title: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  description: {
    id: 'Fill in this form to contact the site owners.',
    defaultMessage: 'Fill in this form to contact the site owners.',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  nameDescription: {
    id: 'Please enter your full name.',
    defaultMessage: 'Please enter your full name.',
  },
  from: {
    id: 'From',
    defaultMessage: 'From',
  },
  fromDescription: {
    id: 'Please enter your e-mail address',
    defaultMessage: 'Please enter your e-mail address',
  },
  subject: {
    id: 'Subject',
    defaultMessage: 'Subject',
  },
  subjectDescription: {
    id: 'Please, enter a short summary of the message',
    defaultMessage: 'Please, enter a short summary of the message',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  messageDescription: {
    id: 'Please enter the message you want to send',
    defaultMessage: 'Please enter the message you want to send',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  messageandsenderrequired: {
    id: 'Both sender address and message are required',
    defaultMessage: 'Both sender address and message are required',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  successMessageSentTitle: {
    id: 'Info',
    defaultMessage: 'Info',
  },
  successMessageSentBody: {
    id:
      'A mail has now been sent to the site administrator regarding your questions and/or comments.',
    defaultMessage:
      'A mail has now been sent to the site administrator regarding your questions and/or comments.',
  },
  feedbackThanks: {
    id: 'Thank you for your feedback',
    defaultMessage: 'Thank you for your feedback',
  },
});

/**
 * Contact form class.
 * @class ContactForm
 * @extends Component
 */
class ContactForm extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    addMessage: PropTypes.func.isRequired,
    emailNotification: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContactForm
   */
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: null,
      isSuccessful: false,
      name: '',
      senderAddress: '',
      subject: '',
      message: '',
    };
  }

  /**
   * On submit handler
   * @method onSubmit
   * @param {Object} data object.
   * @returns {undefined}
   */
  onSubmit() {
    const { name, subject, senderAddress, message } = this.state;

    if (senderAddress && message) {
      if (this.validateEmail(senderAddress)) {
        this.props.emailNotification(senderAddress, message, name, subject);
        this.setState({
          error: null,
          isSuccessful: true,
        });
      } else {
        this.setState({
          error: {
            message: 'Bitte geben Sie eine gültige Email-Adresse ein.',
          },
        });
      }
    } else {
      this.setState({
        error: {
          message: this.props.intl.formatMessage(
            messages.messageandsenderrequired,
          ),
        },
      });
    }
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  validateEmail = (email) => {
    //eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const error = re.test(String(email).toLowerCase());
    return error;
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { name, subject, senderAddress, message } = this.state;

    if (this.state.isSuccessful) {
      return (
        <header>
          <h1 className="documentFirstHeading">
            <FormattedMessage id="Contact form" defaultMessage="Contact form" />
          </h1>
          <p className="description">
            <FormattedMessage
              id="Thank you for your feedback"
              defaultMessage="Thank you for your feedback"
            />
          </p>
        </header>
      );
    }

    return (
      <div id="page-contact-form">
        <Helmet title={this.props.intl.formatMessage(messages.title)} />
        <h1 className="documentFirstHeading">Kontakt</h1>
        <p className="description">
          Haben Sie Feedback, Fragen oder Anregungen? Melden Sie sich gern bei
          uns, wir freuen uns auf Ihre Nachricht!
        </p>
        <Container>
          <Form method="post" onSubmit={this.onSubmit}>
            {this.state.error && (
              <Message
                icon="warning"
                negative
                attached
                header={this.props.intl.formatMessage(messages.error)}
                content={this.state.error.message}
              />
            )}

            <Segment basic>
              <Form.Field inline>
                <Grid>
                  <Grid.Row stretched>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="name">NAME</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8">
                      <Input
                        id="name"
                        name="name"
                        value={name}
                        onChange={this.handleChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Segment>
            <Segment basic>
              <Form.Field inline required>
                <Grid>
                  <Grid.Row stretched>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="senderAddress">E-MAIL ADRESSE</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8">
                      <Input
                        required
                        id="senderAddress"
                        name="senderAddress"
                        value={senderAddress}
                        onChange={this.handleChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Segment>
            <Segment basic>
              <Form.Field inline>
                <Grid>
                  <Grid.Row stretched>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="subject">BETREFF</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8">
                      <Input
                        id="subject"
                        name="subject"
                        value={subject}
                        onChange={this.handleChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Segment>
            <Segment basic>
              <Form.Field inline required>
                <Grid>
                  <Grid.Row stretched>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="message">IHRE NACHRICHT</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8">
                      <TextArea
                        id="message"
                        name="message"
                        value={message}
                        onChange={this.handleChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Segment>
            <Segment basic>
              <Button
                type="submit"
                floated="right"
                color="blue"
                size="big"
                style={{ textTransform: 'uppercase' }}
              >
                <FormattedMessage id="Send" defaultMessage="Send" />
              </Button>
            </Segment>
          </Form>
        </Container>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      loading: state.emailNotification.loading,
      loaded: state.emailNotification.loaded,
      error: state.emailNotification.error,
    }),
    (dispatch) =>
      bindActionCreators({ addMessage, emailNotification }, dispatch),
  ),
)(ContactForm);
