/**
 * Homepage sprachmodule actions.
 * @module actions/homepage/sprachmodule
 */

import { GET_HOMEPAGE_SPRACHMODULE } from '../../constants/ActionTypes';

/**
 * Get Homepage sprachmodule items.
 * @function getHomePageSprachmodule
 * @returns {Object} Homepage sprachmodule action.
 */
export default function getHomePageSprachmodule() {
  return {
    type: GET_HOMEPAGE_SPRACHMODULE,
    request: {
      op: 'get',
      path: '/sprachmodule?fullobjects',
    },
  };
}
