/**
 * Current user reducer.
 * @module reducers/currentUser
 */

import { GET_CURRENT_USER, NO_CURRENT_USER } from '../../constants/ActionTypes';

const initialState = {
  user: {
    roles: [],
  },
  get: {
    error: null,
    loaded: false,
    loading: false,
  },
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Users reducer.
 * @function users
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_CURRENT_USER}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_CURRENT_USER}_SUCCESS`:
      return {
        ...state,
        user: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_CURRENT_USER}_FAIL`:
      return {
        ...state,
        user: {},
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error.error,
        },
      };
    case `${NO_CURRENT_USER}`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: 'No current user found, anonymous session.',
        },
      };
    default:
      return state;
  }
}
