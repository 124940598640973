import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import { Youtube } from '../../../components';

/**
 * MediaPortlet view component class.
 * @class MediaPortlet
 * @extends Component
 */
class MediaPortlet extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
      author_image: PropTypes.object,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string,
          '@type': PropTypes.string,
          description: PropTypes.string,
          review_state: PropTypes.string,
          title: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
      portlet_youtube: PropTypes.string,
      portlet_youtube_image: PropTypes.object,
    }).isRequired,
  };

  state = { isVideoPlaying: false };

  toggleVideoDisplay = () => {
    this.setState({ isVideoPlaying: true });
  };
  /**
   * Render
   * @returns {string} Markup
   * @memberof AkteureView
   */
  render() {
    const imageFields = [2, 3, 4].filter(
      (index) => this.props.content[`portlet_image_${index}`],
    );

    const moreImageFields = [5, 6, 7].filter(
      (index) => this.props.content[`portlet_image_${index}`],
    );

    const youtubeFields = [2].filter(
      (index) => this.props.content[`portlet_youtube_${index}`],
    );

    return (
      <aside className="media-portlet">
        {this.props.content.portlet_youtube &&
          !this.props.content.portlet_youtube_image && (
            <Youtube
              url={this.props.content.portlet_youtube}
              autoplay={false}
            />
          )}
        {this.props.content.portlet_youtube &&
          this.props.content.portlet_youtube_image && (
            <div className="youtube-portlet-wrapper">
              <Youtube
                url={this.props.content.portlet_youtube}
                autoplay={false}
                previewImage={this.props.content.portlet_youtube_image}
              />
            </div>
          )}

        {imageFields.map((index) => (
          <a
            href={this.props.content[`portlet_link_${index}`]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={
                this.props.content[`portlet_image_${index}`].scales.preview
                  .download
              }
            />
          </a>
        ))}

        {this.props.content.portlet_youtube &&
          !this.props.content.portlet_youtube_image &&
          youtubeFields.map((index) => (
            <Youtube
              url={this.props.content[`portlet_youtube_${index}`]}
              autoplay={false}
            />
          ))}

        {this.props.content.portlet_youtube &&
          this.props.content.portlet_youtube_image &&
          youtubeFields.map((index) => (
            <div className="youtube-portlet-wrapper">
              <Youtube
                url={this.props.content[`portlet_youtube_${index}`]}
                autoplay={false}
                previewImage={
                  this.props.content[`portlet_youtube_image_${index}`]
                }
              />
            </div>
          ))}

        {moreImageFields.map((index) => (
          <a
            href={this.props.content[`portlet_link_${index}`]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={
                this.props.content[`portlet_image_${index}`].scales.preview
                  .download
              }
            />
          </a>
        ))}
      </aside>
    );
  }
}

export default MediaPortlet;
