/**
 * Single Video component.
 * @module components/theme/View/SingleVideo
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Youtube } from '../../components';

/**
 * Single Video component class.
 * @function SingleVideo
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const SingleVideo = ({ url, className }) => (
  <div className={className}>
    <Youtube url={url} />
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SingleVideo.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SingleVideo.defaultProps = {
  className: null,
};

export default SingleVideo;
