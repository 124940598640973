import React from 'react';
import SearchWidget from './SearchWidget';

const View = (props) => {
  return (
    <div className="block search">
      <SearchWidget pathname={props.pathname} />
    </div>
  );
};

export default View;
