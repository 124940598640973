/**
 * ThemenweltList view component.
 * @module components/theme/View/Listings/ThemenweltList
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Grid, Button } from 'semantic-ui-react';

import circleSVG from '../../View/Albums/circle-yellow.svg';

/**
 * ThemenweltList component class.
 * @function ThemenweltList
 * @param {array} items Items object.
 * @returns {string} Markup of the component.
 */

const ThemenweltList = ({ items }) => (
  <Grid style={{ marginTop: '50px' }} stackable>
    {items.map((item, i) => (
      <Grid.Column width={6}>
        <div className="themenwelt-card">
          <div className="header">
            <img className="orange circle" src={circleSVG} alt=""></img>
            <span className={cx('counter', { doubled: i >= 9 })}>{i + 1}</span>
          </div>
          <h3>{item.title}</h3>
          <div className="content">
            <div
              className={cx('image container', {
                noImage: item.opengraph_image === null,
              })}
            >
              {item.description && (
                <span
                  className={cx({ withImage: item.opengraph_image?.download })}
                >
                  {item.description}
                </span>
              )}

              {item.opengraph_image?.download && (
                <div style={{ textAlign: 'center' }}>
                  <img src={item.opengraph_image.download} alt=""></img>
                </div>
              )}
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button as={Link} to={item.url} width="80%">
                Zur Lektion
              </Button>
            </div>
          </div>
        </div>
      </Grid.Column>
    ))}
  </Grid>
);
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ThemenweltList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.object,
      '@type': PropTypes.string,
    }),
  ).isRequired,
};

export default ThemenweltList;
