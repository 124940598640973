/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container, Button } from 'semantic-ui-react';
import catJPG from '../../../../../../public/cat_404.jpg';

const messages = defineMessages({
  notFound: {
    id:
      'We apologize for the inconvenience, but the page you were trying to access is not at this address. You can use the links below, to help you find what you are looking for.',
    defaultMessage:
      'Entschuldigung, aber die Webseite, die Sie versucht haben zu erreichen, ist hier nicht verfügbar. Bitte benutzen Sie die aufgeführten Verlinkungen, um zu finden, was Sie gesucht haben.',
  },
});

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */

const NotFound = (intl) => (
  <Container className="view-wrapper notFound">
    <h1>
      <FormattedMessage
        id="This page does not seem to exist…"
        defaultMessage="This page does not seem to exist…"
      />
    </h1>
    <p className="description">{intl.intl.formatMessage(messages.notFound)}</p>
    <p>
      <FormattedMessage
        id="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
        defaultMessage="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
        values={{
          site_admin: (
            <Link to="/contact-form">
              <FormattedMessage
                id="Site Administration"
                defaultMessage="Site Administration"
              />
            </Link>
          ),
        }}
      />
    </p>
    <p style={{ marginBottom: '21px' }}>
      <FormattedMessage id="Thank you." defaultMessage="Thank you." />
    </p>
    <img src={catJPG} alt="cat-jpg"></img>
    <br />
    <a href="https://www.vhs-ehrenamtsportal.de/community">
      <Button>Zum Community-Bereich</Button>
    </a>
    <Button
      onClick={() => {
        window.history.back();
      }}
    >
      Zurück zur letzten Seite
    </Button>
  </Container>
);

export default injectIntl(NotFound);
