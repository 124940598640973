import React from 'react';
import { Segment } from 'semantic-ui-react';

import ButtonData from './ButtonData';

const ButtonSidebar = (props) => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>Button Block</h2>
      </header>

      <ButtonData {...props} />
    </Segment.Group>
  );
};

export default ButtonSidebar;
