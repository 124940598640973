/**
 * Homepage nachrichten elements reducer.
 * @module reducers/homepage/nachrichten
 */
import { has } from 'lodash';

import { GET_HOMEPAGE_NACHRICHTEN } from '../../constants/ActionTypes';

import { flattenToAppURL } from '@plone/volto/helpers';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  content: {
    items: [],
  },
};

/**
 * Nachrichten for homepage reducer.
 * @function nachrichten
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function nachrichten(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_HOMEPAGE_NACHRICHTEN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_HOMEPAGE_NACHRICHTEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        content: {
          items: has(action, 'result.items')
            ? action.result.items.map((item) => ({
                ...item,
                url: flattenToAppURL(item['@id']),
              }))
            : [],
        },
      };
    case `${GET_HOMEPAGE_NACHRICHTEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        content: {
          items: [],
        },
      };
    default:
      return state;
  }
}
