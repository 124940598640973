/**
 * AkteureView view component.
 * @module components/theme/View/AkteureView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Container, Button, Menu } from 'semantic-ui-react';
import { sortAkteure } from '../../actions';
import qs from 'query-string';

import { searchContent } from '@plone/volto/actions';
import AkteureAlbum from './Albums/AkteureAlbum';
import SingleVideo from './SingleVideo';
import AkteurContact from '../AkteurContact/AkteurContact';

/**
 * AkteureView view component class.
 * @class AkteureView
 * @extends Component
 */
class AkteureView extends Component {
  static propTypes = {
    location: PropTypes.shape({ search: PropTypes.object }).isRequired,
    sortAkteure: PropTypes.func.isRequired,
    searchContent: PropTypes.func.isRequired,
    team: PropTypes.arrayOf(PropTypes.object),
    expertInnen: PropTypes.arrayOf(PropTypes.object),
    ehrenamtliche: PropTypes.arrayOf(PropTypes.object),
    content: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
      email: PropTypes.string,
      funktion: PropTypes.string,
      image: PropTypes.shape({
        scales: PropTypes.shape({
          preview: PropTypes.shape({
            download: PropTypes.string,
          }),
        }),
      }),
    }).isRequired,
  };

  static defaultProps = {
    team: [],
    expertInnen: [],
    ehrenamtliche: [],
  };

  state = {
    currentAkteurType: 'expertInnen',
    currentVideoUrl: '',
    akteureVideoPlaylist: '',
    emailToName: '',
    emailToAddress: '',
    showContactForm: false,
  };

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  componentDidMount() {
    const group = this.props.group ? this.props.group : 'expertInnen';

    this.props.searchContent('', {
      portal_type: 'Akteur',
      b_size: 200,
      fullobjects: true,
      sort_on: 'getObjPositionInParent',
    });
    this.setState(() => ({
      currentAkteurType: group,
      currentVideoUrl: this.getRandomVideo(group),
      akteureVideoPlaylist: this.getVideoPlaylist(group),
    }));
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.group !== nextProps.group) {
      this.setState(() => ({
        currentAkteurType: nextProps.group,
        currentVideoUrl: this.getRandomVideo(nextProps.group),
        akteureVideoPlaylist: this.getVideoPlaylist(nextProps.group),
      }));
    }
    if (this.props.searchresults !== nextProps.searchresults) {
      this.props.sortAkteure();
    }
  }

  getRandomVideo = (type = 'ehrenamtliche') => {
    const availableFields = [...Array(7).keys()].map(
      (i) => `video${i}_url_${type.toLowerCase()}`,
    );

    const availableVideos = availableFields.filter(
      (field) => this.props.content[field],
    );

    return this.props.content[
      availableVideos[Math.floor(Math.random() * availableVideos.length)]
    ];
  };

  getVideoPlaylist = (type) => {
    switch (type) {
      case 'expertInnen':
        return 'https://www.youtube.com/playlist?list=PLCl20eW39ZmtQS_WJ1ywEzApk9_fTRrDM';
      case 'ehrenamtliche':
        return 'https://www.youtube.com/playlist?list=PLCl20eW39ZmsxSrN0UFWVN7JoS0dt3-NU';
      case 'team':
        return '';
      default:
        return 'https://www.youtube.com/playlist?list=PLCl20eW39ZmtQS_WJ1ywEzApk9_fTRrDM';
    }
  };

  handleItemClick = (value) => {
    this.props.history.push(`${this.props.location.pathname}?group=${value}`);
    this.setState(() => ({
      currentAkteurType: value,
      currentVideoUrl: this.getRandomVideo(value),
      akteureVideoPlaylist: this.getVideoPlaylist(value),
    }));
  };

  handleShowForm = (e, title, email) => {
    e.preventDefault();
    this.setState(() => ({
      emailToName: title,
      emailToAddress: email,
      showContactForm: true,
    }));
  };

  /**
   * Render
   * @returns {string} Markup
   * @memberof AkteureView
   */
  render() {
    const { currentAkteurType } = this.state;

    return (
      <Container>
        <div className="view-wrapper view-akteureview">
          <div className="menu-wrapper">
            <Menu compact>
              <Menu.Item
                active={currentAkteurType === 'expertInnen'}
                onClick={() => this.handleItemClick('expertInnen')}
              >
                ExpertInnen
              </Menu.Item>
              <Menu.Item
                active={currentAkteurType === 'ehrenamtliche'}
                onClick={() => this.handleItemClick('ehrenamtliche')}
              >
                Ehrenamtliche
              </Menu.Item>
              <Menu.Item
                active={currentAkteurType === 'team'}
                onClick={() => this.handleItemClick('team')}
              >
                Team
              </Menu.Item>
            </Menu>
          </div>

          {this.state.currentVideoUrl && (
            <SingleVideo
              url={this.state.currentVideoUrl}
              className="main-video"
            />
          )}

          {this.state.akteureVideoPlaylist && (
            <div className="more-videos-button">
              <Button
                className="branded blue"
                as="a"
                href={this.state.akteureVideoPlaylist}
                size="big"
              >
                Mehr Videos von unseren Akteuren
              </Button>
            </div>
          )}

          <div className="akteure-album">
            <AkteureAlbum
              items={this.props[this.state.currentAkteurType]}
              handleShowForm={this.handleShowForm}
            />
          </div>

          {this.state.showContactForm && (
            <div>
              <AkteurContact
                emailToName={this.state.emailToName}
                emailToAddress={this.state.emailToAddress}
              />
            </div>
          )}
        </div>
      </Container>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      expertInnen: state.akteure.expertInnen,
      ehrenamtliche: state.akteure.ehrenamtliche,
      team: state.akteure.team,
      searchresults: state.search.items,
      group: qs.parse(props.location.search).group,
    }),
    {
      sortAkteure,
      searchContent,
    },
  ),
)(AkteureView);
