/**
 * ThemenweltenView view component.
 * @module components/theme/View/ThemenweltenView
 */

import React from 'react';
import PropTypes from 'prop-types';

import AlbumView from './AlbumView';
import ThemenweltenAlbum from './Albums/ThemenweltenAlbum';

/**
 * ThemenweltenView component class.
 * @function ThemenweltenView
 * @param {object} props Items object.
 * @returns {string} Markup of the component.
 */
const ThemenweltenView = (props) => (
  <AlbumView {...props} albumView={ThemenweltenAlbum} />
);

ThemenweltenView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ThemenweltenView;
