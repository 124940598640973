import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import eherenamtsImg from './ehre.png';
import expertsImg from './expe.png';
import teamImg from './team.jpg';

/**
 * AkteurinnenSnippet container class.
 * @class AkteurinnenSnippet
 * @extends Component
 */
export default class AkteurinnenSnippet extends Component {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="akteurinnen-list">
        <Container>
          <h3>
            Akteurinnen und Akteure auf dem Portal:{' '}
            <strong>Wir gestalten Integration!</strong>
          </h3>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={4}>
                <Link to="/akteure?group=ehrenamtliche">
                  <img
                    style={{ width: '100%' }}
                    src={eherenamtsImg}
                    alt="Ehrenamtliche"
                  />
                </Link>
              </Grid.Column>
              <Grid.Column width={4}>
                <Link to="/akteure?group=expertInnen">
                  <img
                    style={{ width: '100%' }}
                    src={expertsImg}
                    alt="ExpertInnen"
                  />
                </Link>
              </Grid.Column>
              <Grid.Column width={4}>
                <Link to="/akteure?group=team">
                  <img style={{ width: '100%' }} src={teamImg} alt="Team" />
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}
