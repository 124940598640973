import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

/**
 * SearchSnippet container class.
 * @class SearchSnippet
 * @extends Component
 */
class SearchSnippet extends Component {
  state = { searchString: '' };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { searchString } = this.state;
    this.props.history.push(`/search?SearchableText=${searchString}`);
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { searchString } = this.state;
    return (
      <div className="search-form">
        <Form className="mobile hidden" onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Input
              placeholder="Suche im gesamten Ehrenamtsportal"
              name="searchString"
              value={searchString}
              onChange={this.handleChange}
            />
            <Button
              className="field branded"
              content="Suchen"
              icon="search"
              labelPosition="left"
              color="blue"
            />
          </Form.Group>
        </Form>
        <Form
          style={{ width: '95%' }}
          className="mobile only"
          onSubmit={this.handleSubmit}
        >
          <Form.Input
            placeholder="Suche im gesamten Ehrenamtsportal"
            name="searchString"
            value={searchString}
            onChange={this.handleChange}
            width={12}
          />
          <Button
            className="field branded"
            content="Suchen"
            icon="search"
            labelPosition="left"
            color="blue"
            size="large"
          />
        </Form>
      </div>
    );
  }
}

export default withRouter(SearchSnippet);
