/**
 * Tags component.
 * @module components/theme/Tags/Tags
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

/**
 * Tags component class.
 * @function Tags
 * @param {array} tags Array of tags.
 * @returns {string} Markup of the component.
 */
const Tags = ({ tags }) =>
  tags && tags.length > 0 ? (
    <Container className="tags">
      {tags.map((tag) => (
        <Link
          className="ui label"
          to={`/search?Subject=${tag.token || tag}`}
          key={tag.token || tag}
        >
          {tag.title || tag}
        </Link>
      ))}
    </Container>
  ) : (
    <span />
  );

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
Tags.defaultProps = {
  tags: null,
};

export default Tags;
