export const keywords = [
  {
    keyword: 'Engagementfinder',
    id: '/engagementfinder',
    title: 'Engagementfinder',
    type: 'MatchingTool',
    searchwords: [
      'engagementfinder',
      'engagement-finder',
      'engagement finder',
      'finder',
      'matching-tool',
      'matching tool',
      'matching',
    ],
  },
  {
    keyword: 'Community',
    id: '/austausch/communitybereich',
    title: 'Community',
    searchwords: [
      'forum',
      'community',
      'discourse',
      'communitybereich',
      'community bereich',
    ],
  },
];
