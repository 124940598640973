import { UPDATE_COOKIE_CONSENT } from '../../constants/ActionTypes';

const initialState = {};

export default function cookieConsent(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_COOKIE_CONSENT:
      return {
        ...state,
        ...action.cookie,
      };
    default:
      return state;
  }
}
