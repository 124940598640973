import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid, Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import moment from 'moment';
import config from '@plone/volto/registry';

import defaultAvatarPNG from '../../../../../components/Comments/default-avatar.png';
import UserCard from './UserCard';

const messages = defineMessages({
  comment: {
    id: 'Comment',
    defaultMessage: 'Comment',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  edit: {
    id: 'Edit',
    defaultMessage: 'Edit',
  },
});

const Comment = ({ item, onDelete }) => {
  const node = useRef();
  const [hasCardOpen, setHasCardOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setHasCardOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [hasCardOpen]);

  return (
    <div className="comment">
      <Grid stackable>
        <Grid.Column width={10}>
          <FormattedMessage
            id="{user}"
            defaultMessage="{user}"
            values={{
              user: (
                <>
                  {item.author_image ? (
                    <img
                      className="user-portrait"
                      src={item.author_image}
                      alt=""
                    ></img>
                  ) : (
                    item.author_image === null && (
                      <img
                        className="user-portrait"
                        src={defaultAvatarPNG}
                        alt=""
                      ></img>
                    )
                  )}
                  <span>
                    <Button
                      className="user-name"
                      id="user-name"
                      onClick={() => {
                        setHasCardOpen(!hasCardOpen);
                      }}
                    >
                      {item.author_name}

                      {hasCardOpen && (
                        <div ref={node}>
                          <UserCard
                            userId={item.author_username}
                            avatar={item.author_image}
                          />
                        </div>
                      )}
                    </Button>
                  </span>
                </>
              ),
            }}
          />
          <span title={moment(item.creation_date).format('LLLL')}>
            -&nbsp; {moment(item.creation_date).fromNow()}:
          </span>
        </Grid.Column>
      </Grid>
      <Segment clearing>
        {item.text['mime-type'] === 'text/html' ? (
          <div dangerouslySetInnerHTML={{ __html: item.text.data }} />
        ) : (
          item.text.data
        )}
        {item.is_deletable && (
          <Button
            aria-label="Löschen"
            onClick={onDelete}
            value={item['@id'].replace(config.settings.apiPath, '')}
            color="red"
            floated="right"
          >
            <FormattedMessage id="Delete" defaultMessage="Delete" />
          </Button>
        )}
        {item.is_editable && (
          <Button
            aria-label={this.props.intl.formatMessage(messages.edit)}
            onClick={this.onEdit}
            floated="right"
            value={{
              id: item['@id'].replace(config.settings.apiPath, ''),
              text: item.text.data,
            }}
          >
            <FormattedMessage id="Edit" defaultMessage="Edit" />
          </Button>
        )}
      </Segment>
      <br />
    </div>
  );
};

export default injectIntl(Comment);
