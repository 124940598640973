/**
 * SprachmodulContent view component.
 * @module components/theme/View/SprachmodulContent
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

/**
 * SprachmodulContent component class.
 * @function SprachmodulContent
 * @param {array} content Content object.
 * @returns {string} Markup of the component.
 */
const SprachmodulContent = ({ content }) => (
  <div>
    {content.title && (
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && <span> &mdash;{` ${content.subtitle}`}</span>}
      </h1>
    )}
    {content.description && (
      <p className="description">{content.description}</p>
    )}
    {content.banner && (
      <div className="documentImage">
        <Image
          alt={content.title}
          title={content.title}
          src={
            content.banner['content-type'] === 'image/svg+xml'
              ? content.banner.download
              : content.banner.scales.big.download
          }
        />
      </div>
    )}
    {!content.banner && content.image && (
      <div className="documentImage">
        <Image
          alt={content.title}
          title={content.title}
          src={
            content.image['content-type'] === 'image/svg+xml'
              ? content.image.download
              : content.image.scales.big.download
          }
        />
      </div>
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SprachmodulContent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      download: PropTypes.string,
      scales: PropTypes.object,
    }),
  }).isRequired,
};

export default SprachmodulContent;
