/**
 * Album view component.
 * @module components/theme/View/AlbumView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DefaultAlbum from './Albums/DefaultAlbum';
import DefaultContent from './Albums/DefaultContent';

import { Container } from 'semantic-ui-react';

/**
 * AlbumView view component class.
 * @class AlbumView
 * @extends Component
 */
export default class AlbumView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          url: PropTypes.string,
          image: PropTypes.object,
          '@type': PropTypes.string,
        }),
      ),
    }).isRequired,
    albumView: PropTypes.func,
    contentView: PropTypes.func,
    clickable: PropTypes.bool,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    albumView: DefaultAlbum,
    contentView: DefaultContent,
    clickable: true,
  };

  /**
   * componentDidMount method.
   * @method componentDidMount
   * @returns {string} Markup for the component.
   */
  componentDidMount() {}

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const AlbumComponent = this.props.albumView;
    const ContentComponent = this.props.contentView;

    if (
      this.props.albumView !== DefaultAlbum &&
      this.props.contentView !== DefaultContent
    ) {
      return (
        <div>
          {/* TODO: We need to improve this with fragments in React 16 */}
          <ContentComponent content={this.props.content} />
          <AlbumComponent
            items={this.props.content.items}
            clickable={this.props.clickable}
          />
        </div>
      );
    }

    return (
      <Container>
        <div className="view-wrapper">
          <article id="content">
            <section id="content-core">
              <ContentComponent content={this.props.content} />
              <AlbumComponent
                items={this.props.content.items}
                clickable={this.props.clickable}
              />
            </section>
          </article>
        </div>
      </Container>
    );
  }
}
