/**
 * Youtube view component.
 * @module components/theme/View/YoutubeView
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Youtube } from '../../components';

/**
 * Youtube view component class.
 * @function YoutubeView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const YoutubeView = ({ content, tiled }) => (
  <div className="view-wrapper">
    {!tiled && (
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && <span> &mdash;{` ${content.subtitle}`}</span>}
      </h1>
    )}
    {tiled && (
      <h4>
        {content.title}
        {content.subtitle && <span> &mdash;{` ${content.subtitle}`}</span>}
      </h4>
    )}
    {tiled && content.description && <p>{content.description}</p>}
    <Youtube url={content.youtube_embed_url} />
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
YoutubeView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    youtube_embed_url: PropTypes.string,
  }).isRequired,
  tiled: PropTypes.bool,
};

YoutubeView.defaultProps = {
  tiled: false,
};

export default YoutubeView;
