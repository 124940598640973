/**
 * DefaultList view component.
 * @module components/theme/View/Listings/DefaultList
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';

/**
 * DefaultList component class.
 * @function DefaultList
 * @param {array} items Items object.
 * @returns {string} Markup of the component.
 */
const DefaultList = ({ items }) => (
  <List divided relaxed="very">
    {items.map((item) => (
      <List.Item as="article" key={item.url}>
        <Link to={item.url} title={item['@type']}>
          <List.Header content={item.title} />
        </Link>
        {item.description && <List.Description content={item.description} />}
      </List.Item>
    ))}
  </List>
);
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.object,
      '@type': PropTypes.string,
    }),
  ).isRequired,
};

export default DefaultList;
