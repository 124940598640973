import React from 'react';
import { Segment } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { TextWidget } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import { compose } from 'redux';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';

const Sidebar = ({ data, intl, onChangeBlock, block, openObjectBrowser }) => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>Testimonial Block</h2>
      </header>
      <Segment className="form sidebar-image-data">
        <TextWidget
          id="Origin"
          title="Bild Quelle"
          required={false}
          value={data.url?.split('/').slice(-1)[0]}
          icon={data.url ? clearSVG : navTreeSVG}
          iconAction={
            data.url
              ? () => {
                  onChangeBlock(block, {
                    ...data,
                    url: '',
                  });
                }
              : () => openObjectBrowser()
          }
          onChange={() => {}}
        />
        <TextWidget
          id="external"
          title="Titel"
          required={true}
          value={data.title}
          onChange={(event, value) => {
            onChangeBlock(block, {
              ...data,
              title: value,
            });
          }}
        />
        <TextWidget
          id="external"
          title="Beschreibung"
          required={true}
          value={data.description}
          onChange={(event, value) => {
            onChangeBlock(block, {
              ...data,
              description: value,
            });
          }}
        />
        <TextWidget
          id="external"
          title="Button URL"
          required={true}
          value={data.href?.split('/').slice(-1)[0]}
          icon={data.href ? clearSVG : navTreeSVG}
          iconAction={
            data.href
              ? () => {
                  onChangeBlock(block, {
                    ...data,
                    href: '',
                  });
                }
              : () =>
                  openObjectBrowser({
                    mode: 'link',
                  })
          }
          onChange={(event, value) => {
            onChangeBlock(block, {
              ...data,
              href: value,
            });
          }}
        />
        <TextWidget
          id="external"
          title="Button Text"
          required={true}
          value={data.buttonText}
          onChange={(event, value) => {
            onChangeBlock(block, {
              ...data,
              buttonText: value,
            });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

export default compose(withObjectBrowser, injectIntl)(Sidebar);
