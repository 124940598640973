/**
 * Current user actions.
 * @module actions/currentUser/currentUser
 */
import jwtDecode from 'jwt-decode';

import { GET_CURRENT_USER, NO_CURRENT_USER } from '../../constants/ActionTypes';

/**
 * Get current user function
 * @function getCurrentUser
 * @param {string} id User id
 * @returns {Object} Get user action
 */
export default function getCurrentUser() {
  return (dispatch, getState) => {
    const { token } = getState().userSession;

    if (token) {
      const userId = jwtDecode(token).sub;

      dispatch({
        type: GET_CURRENT_USER,
        request: {
          op: 'get',
          path: `/@users/${userId}`,
        },
      });
    }

    if (!token) {
      dispatch({
        type: NO_CURRENT_USER,
      });
    }
  };
}
