/**
 * emailSend actions.
 * @module actions/emailSend/emailSend
 */

import { EMAIL_SEND, EMAIL_NOTIFICATION } from '../../constants/ActionTypes';

/**
 * Email notification function
 * @function emailSend
 * @param {string} to User id
 * @param {string} from User id
 * @param {string} message Old password.
 * @param {string} name New password.
 * @param {string} subject New password.
 * @returns {Object} Email Send action.
 */
export default function emailSend(to, from, message, name, subject) {
  return {
    type: EMAIL_SEND,
    request: {
      op: 'post',
      path: '/@email-send',
      data: {
        to,
        from,
        message,
        name,
        subject,
      },
    },
  };
}

/**
 * Email notification function
 * @function emailNotification
 * @param {string} from User id
 * @param {string} message
 * @param {string} name
 * @returns {Object}
 */
export function emailNotification(
  from,
  message,
  name,
  organisation,
  ehrenamtlich,
  hauptamtlich,
  eventList,
) {
  return {
    type: EMAIL_NOTIFICATION,
    request: {
      op: 'post',
      path: '/@email-notification',
      data: {
        from,
        message,
        name,
        organisation,
        ehrenamtlich,
        hauptamtlich,
        eventList,
      },
    },
  };
}
