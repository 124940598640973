import React from 'react';
import SearchWidget from './SearchWidget';

const Edit = (props) => {
  return (
    <div className="block search edit">
      <SearchWidget pathname={props.pathname} />
    </div>
  );
};

export default Edit;
