/**
 * DefaultAlbum view component.
 * @module components/theme/View/Listings/DefaultAlbum
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Image } from 'semantic-ui-react';

/**
 * DefaultAlbum component class.
 * @function DefaultAlbum
 * @param {array} items Items object.
 * @returns {string} Markup of the component.
 */
const DefaultAlbum = ({ items, clickable }) => (
  <Card.Group stackable doubling itemsPerRow={3}>
    {items.map((item) => (
      <Card
        as={clickable ? Link : null}
        to={clickable ? item.url : null}
        title={item.title}
        key={item.url}
      >
        <div className="image-wrapper">
          {item.image && (
            <Image
              src={
                item.image.width > 0
                  ? item.image.scales.preview.download
                  : item.image.download
              }
              alt={item.image_caption ? item.image_caption : item.title}
            />
          )}
        </div>
        <Card.Content>
          <Card.Header content={item.title} textAlign="center" />
          <Card.Description content={item.description} />
        </Card.Content>
      </Card>
    ))}
    {/* Workaround while Flexbox 2.0 doesn't implement last row alignment */}
    {[...Array(3)].map((e, i) => (
      <Card key={i} />
    ))}
  </Card.Group>
);
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultAlbum.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.object,
      '@type': PropTypes.string,
    }),
  ).isRequired,
  clickable: PropTypes.bool,
};

DefaultAlbum.defaultProps = {
  clickable: true,
};

export default DefaultAlbum;
