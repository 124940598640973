import { GET_JOB_OFFER_CATEGORIES } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  loaded: false,
  loading: false,
};

/**
 * jobOfferCategories reducer.
 * @function navigation
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function jobOfferCategories(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_JOB_OFFER_CATEGORIES}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_JOB_OFFER_CATEGORIES}_SUCCESS`:
      return {
        ...state,
        error: null,
        items: action.result.categories,
        loaded: true,
        loading: false,
      };
    case `${GET_JOB_OFFER_CATEGORIES}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
