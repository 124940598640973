/**
 * Summary view component.
 * @module components/theme/View/NewsListView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image, Container } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { getHomePageNachrichten } from '../../actions';

/**
 * Summary view component class.
 * @function NewsListView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
class NewsListView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          url: PropTypes.string,
          image: PropTypes.object,
          image_caption: PropTypes.string,
          '@type': PropTypes.string,
        }),
      ),
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
    getHomePageNachrichten: PropTypes.func.isRequired,
  };

  /**
   * Property types.
   * @property {Object} defaultProps Property types.
   * @static
   */
  static defaultProps = {
    items: [],
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getHomePageNachrichten();
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container className="view-wrapper">
        <article id="content">
          <header>
            <h1 className="documentFirstHeading">
              {this.props.content.title}
              {this.props.content.subtitle && (
                <span> &mdash;{` ${this.props.content.subtitle}`}</span>
              )}
            </h1>
            {this.props.content.description && (
              <p className="description">{this.props.content.description}</p>
            )}
          </header>
          <section id="content-core">
            {this.props.items.map((item) => (
              <article key={item.url} style={{ marginBottom: '20px' }}>
                <h2 className="title">
                  <Link to={item.url} title={item['@type']}>
                    {item.title}
                  </Link>
                </h2>
                {item.image && (
                  <Image
                    clearing
                    floated="right"
                    alt={item.image_caption ? item.image_caption : item.title}
                    src={item.image.scales.thumb.download}
                  />
                )}
                {item.description && <p>{item.description}</p>}
                <p>
                  <Link to={item.url}>
                    <FormattedMessage
                      id="Read More…"
                      defaultMessage="Read More…"
                    />
                  </Link>
                </p>
              </article>
            ))}
          </section>
        </article>
      </Container>
    );
  }
}

export default connect(
  (state) => ({ items: state.homepageNachrichten.content.items }),
  {
    getHomePageNachrichten,
  },
)(NewsListView);
