import React from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import PropTypes from 'prop-types';

/**
 * ContentSwapper view component class.
 * @class ContentSwapper
 * @extends Component
 */
class ContentSwapper extends React.Component {
  static propTypes = {
    isVideoPlaying: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    toggleVideoDisplay: PropTypes.func.isRequired,
    style: PropTypes.objectOf(PropTypes.string),
  };

  static defaultProps = {
    style: {},
  };

  state = { index: 0 };

  /**
   * componentWillReceiveProps method.
   * @method componentWillReceiveProps
   * @param {object} nextProps nextProps
   * @returns {string} Markup for the component.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isVideoPlaying !== this.props.isVideoPlaying) {
      const index = this.props.isVideoPlaying ? 0 : 1;
      this.setState({
        index: index >= React.Children.count(this.props.children) ? 0 : index,
      });
    }
  }

  handleClick = () => {
    const index = this.props.isVideoPlaying ? 0 : 1;
    this.setState({
      index: index >= React.Children.count(this.props.children) ? 0 : index,
    });
    this.props.toggleVideoDisplay();
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const content = React.Children.toArray(this.props.children);
    const { style = {} } = this.props;

    const newStyle = {
      ...style,
      cursor: 'pointer',
    };

    const { isVideoPlaying, toggleVideoDisplay, ...rest } = this.props;

    return (
      <ReactCSSTransitionReplace
        {...rest}
        style={newStyle}
        onClick={this.handleClick}
      >
        {content[this.state.index]}
      </ReactCSSTransitionReplace>
    );
  }
}

export default ContentSwapper;
