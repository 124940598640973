/**
 * Personaltools component.
 * @module components/theme/Personaltools/Personaltools
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

/**
 * Personaltools container class.
 * @class Personaltools
 * @extends Component
 */
class Personaltools extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      this.props.token && (
        <div className="personaltools">
          <Link className="item" to="/logout">
            <Icon name="chevron right" size="small" className="fontawesome" />{' '}
            Abmelden
          </Link>
          <Button
            as={Link}
            to="/mein-profil"
            content="Mein Profil"
            icon="user"
            labelPosition="left"
            color="blue"
          />
        </div>
      )
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(Personaltools);
