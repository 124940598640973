/**
 * MethodenkofferContent view component.
 * @module components/theme/View/MethodenkofferContent
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Document view component class.
 * @function MethodenkofferContent
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const MethodenkofferContent = ({ content }) => (
  <div className="view-wrapper">
    {(content.title || content.description) && (
      <header>
        <h1 className="documentFirstHeading">
          {content.title}
          {content.subtitle && <span> &mdash;{` ${content.subtitle}`}</span>}
        </h1>
        <p className="description">
          Der Methodenkoffer Grundbildung und Integration eignet sich
          hervorragend als Arbeitshilfe sowohl in der Alphabetisierung und
          Grundbildung als auch für die Flüchtlingshilfe und Flüchtlingsarbeit.
          Er enthält spielerische Methoden und Materialien, die Sie – abseits
          von professionellen Kursangeboten – einfach und unkompliziert in Ihrem
          ehrenamtlichen Engagement rund um die Themen Sprechen, Hören, Lesen
          und Schreiben nutzen und anwenden können. Vollkommen gleich, ob Sie
          sich in einem ehrenamtlichen Arbeitskreis, bei der Integration von
          asylsuchenden und geflüchteten Menschen oder in der Unterstützung von
          funktionalen Analphabetinnen und Analphabeten engagieren.
        </p>
        {/* {content.description && (
          <p className="description">{content.description}</p>
        )} */}
      </header>
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
MethodenkofferContent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default MethodenkofferContent;
