/**
 * Routes.
 * @module routes
 */

import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import {
  ContactForm,
  DiscourseSSORedirect,
  MyProfileSaved,
  EventForm,
} from './components';
import { PersonalInformation } from '@plone/volto/components';
import config from '@plone/volto/registry';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App,
    routes: [
      {
        path: '/discourse_sso',
        component: DiscourseSSORedirect,
        exact: true,
      },
      {
        path: '/mein-profil',
        component: PersonalInformation,
        exact: true,
      },
      {
        path: '/mein-profil-gespeichert',
        component: MyProfileSaved,
        exact: true,
      },
      {
        path: '/kontakt',
        component: ContactForm,
        exact: true,
      },
      {
        path: '/zur-seminarreihe-anmelden',
        component: EventForm,
        exact: true,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
