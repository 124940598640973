/**
 * OVERRIDE Body.jsx
 * REASON: Modify video block to only be displayed when respective cookies are accepted
 * FILE: https://github.com/plone/volto/blob/6cb3cfab77e9ddc9e32dc29dd45652881db91542/src/components/manage/Blocks/Video/Body.jsx
 * FILE VERSION: Volto 16.2.0
 * DATE: 2021-01-11
 * DEVELOPER: @jackahl
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * Body video block.
 * @module components/manage/Blocks/Video/Body
 */

import React from 'react';
import PropTypes from 'prop-types';
// START CUSTOMIZATION
import { Embed, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { withCookies } from 'react-cookie';
import cx from 'classnames';
import {
  isInternalURL,
  getParentUrl,
  flattenToAppURL,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { updateCookieConsent } from '@package/actions';

/**
 * View video block class.
 * @class View
 * @extends Component
 */
const View = ({ data, cookies }) => {
  const dispatch = useDispatch();
  const youtubeConsent = useSelector((state) => state.cookieConsent.youtube);

  const enableYoutube = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    cookies.set('confirm_youtube', 1, {
      path: '/',
      expires: expiryDate,
    });
    dispatch(updateCookieConsent({ youtube: true }));
  };
  return (
    <div
      className={cx(
        'block video align',
        {
          center: !Boolean(data.align),
        },
        data.align,
      )}
    >
      {data.url && (
        <div
          className={cx('video-inner', {
            'full-width': data.align === 'full',
          })}
        >
          {data.url.match('youtu') ? (
            youtubeConsent ? (
              <>
                {data.url.match('list') ? (
                  data.preview_image ? (
                    <Embed
                      url={`https://www.youtube.com/embed/videoseries?list=${
                        data.url.match(/^.*\?list=(.*)$/)[1]
                      }`}
                      placeholder={
                        isInternalURL(data.preview_image)
                          ? `${flattenToAppURL(
                              data.preview_image,
                            )}/@@images/image`
                          : data.preview_image
                      }
                      defaultActive
                      autoplay={false}
                    />
                  ) : (
                    <Embed
                      url={`https://www.youtube.com/embed/videoseries?list=${
                        data.url.match(/^.*\?list=(.*)$/)[1]
                      }`}
                      icon="play"
                      defaultActive
                      autoplay={false}
                    />
                  )
                ) : data.preview_image ? (
                  <Embed
                    id={
                      data.url.match(/.be\//)
                        ? data.url.match(/^.*\.be\/(.*)/)[1]
                        : data.url.match(/^.*\?v=(.*)$/)[1]
                    }
                    source="youtube"
                    placeholder={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    icon="play"
                    autoplay={false}
                  />
                ) : (
                  <Embed
                    id={
                      data.url.match(/.be\//)
                        ? data.url.match(/^.*\.be\/(.*)/)[1]
                        : data.url.match(/^.*\?v=(.*)$/)[1]
                    }
                    source="youtube"
                    icon="play"
                    defaultActive
                    autoplay={false}
                  />
                )}
              </>
            ) : (
              <div class="youtube-placeholder">
                <p>
                  Wichtiger Hinweis: Wenn Sie auf diesen Link gehen, werden
                  Inhalte aus folgendem Kanal angezeigt und Verbindungen zu den
                  jeweiligen Serven aufgebaut.
                </p>
                <p>
                  Der DVV e.V. macht sich die durch Links erreichbaren Webseiten
                  Dritter nicht zu eigen und ist für deren Inhalt nicht
                  verantwortlich. Der DVV e.V. hat keinen Einfluss darauf,
                  welche personenbezogenen Daten von Ihnen auf dieser Seite
                  verarbeitet werden. Nähere Informationen können Sie hierzu der
                  Datenschutzerklärung des Anbieters der externen Webseite
                  entnehmen.
                </p>
                <p>
                  Inhalte des folgenden Kanals werden angezeigt:
                  <br />
                  <a href={data.url}>{data.url} </a>
                  <br />
                  <Button
                    className="branded blue"
                    onClick={() => enableYoutube()}
                  >
                    Inhalte von Youtube laden
                  </Button>
                </p>
              </div>
            )
          ) : (
            <>
              {data.url.match('vimeo') ? (
                data.preview_image ? (
                  <Embed
                    id={data.url.match(/^.*\.com\/(.*)/)[1]}
                    source="vimeo"
                    placeholder={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    icon="play"
                    autoplay={false}
                  />
                ) : (
                  <Embed
                    id={data.url.match(/^.*\.com\/(.*)/)[1]}
                    source="vimeo"
                    icon="play"
                    defaultActive
                    autoplay={false}
                  />
                )
              ) : (
                <>
                  {data.url.match('.mp4') ? (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                      src={
                        isInternalURL(
                          data.url.replace(
                            getParentUrl(config.settings.apiPath),
                            '',
                          ),
                        )
                          ? `${data.url}/@@download/file`
                          : data.url
                      }
                      controls
                      poster={
                        data.preview_image
                          ? isInternalURL(data.preview_image)
                            ? `${flattenToAppURL(
                                data.preview_image,
                              )}/@@images/image`
                            : data.preview_image
                          : ''
                      }
                      type="video/mp4"
                    />
                  ) : (
                    <div className="invalidVideoFormat" />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withCookies(View);
// END CUSTOMIZATION
