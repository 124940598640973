/**
 * LerneinheitView view component.
 * @module components/theme/View/LerneinheitView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, List } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

const messages = defineMessages({
  pdf_file: {
    id: 'PDF file',
    defaultMessage: 'PDF Datei',
  },
  word_file: {
    id: 'Word file',
    defaultMessage: 'Word-Datei',
  },
});

/**
 * LerneinheitView class.
 * @class LerneinheitView
 * @extends Component
 */
class LerneinheitView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      pdf_file: PropTypes.shape({
        'content-type': PropTypes.string,
        filename: PropTypes.string,
        download: PropTypes.string,
        size: PropTypes.number,
      }),
      word_file: PropTypes.shape({
        'content-type': PropTypes.string,
        filename: PropTypes.string,
        download: PropTypes.string,
        size: PropTypes.number,
      }),
    }).isRequired,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="view-wrapper">
        <article id="content">
          <header>
            <h1 className="documentFirstHeading">{this.props.content.title}</h1>
          </header>
          <section id="content-core">
            <List link relaxed>
              {this.props.content.pdf_file && (
                <List.Item>
                  <Icon
                    className="fontawesome"
                    name="file pdf outline"
                    title={this.props.intl.formatMessage(messages.pdf_file)}
                  />
                  <List.Content
                    href={this.props.content.pdf_file.download}
                    content={this.props.content.pdf_file.filename}
                    onClick={() =>
                      ReactGA.event({
                        category: 'Download',
                        action: 'file_download',
                        label: this.props.content.pdf_file.download,
                      })
                    }
                  />
                </List.Item>
              )}
              {this.props.content.word_file && (
                <List.Item>
                  <Icon
                    className="fontawesome"
                    name="file word outline"
                    title={this.props.intl.formatMessage(messages.word_file)}
                  />
                  <List.Content
                    href={this.props.content.word_file.download}
                    content={this.props.content.word_file.filename}
                    onClick={() =>
                      ReactGA.event({
                        category: 'Download',
                        action: 'file_download',
                        label: this.props.content.word_file.download,
                      })
                    }
                  />
                </List.Item>
              )}
            </List>
          </section>
        </article>
      </div>
    );
  }
}

export default injectIntl(LerneinheitView);
