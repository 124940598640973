/**
 * Image view component.
 * @module components/theme/View/AkteurView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Icon, Image } from 'semantic-ui-react';
import { AkteurContact } from '../';

/**
 * AkteurView view component class.
 * @function AkteurView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
class AkteurView extends Component {
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
      email: PropTypes.string,
      funktion: PropTypes.string,
      image_caption: PropTypes.string,
      image: PropTypes.shape({
        scales: PropTypes.shape({
          preview: PropTypes.shape({
            download: PropTypes.string,
          }),
        }),
      }),
    }).isRequired,
  };

  state = {
    showContactForm: false,
  };

  handleShowForm = () =>
    this.setState((state) => ({ showContactForm: !state.showContactForm }));

  /**
   * Render
   * @returns {string} Markup
   * @memberof AkteurView
   */
  render() {
    return (
      <Container>
        <div className="view-wrapper view-akteurview">
          <div className="akteur">
            {this.props.content.image && (
              <Image
                centered
                src={this.props.content.image.scales.preview.download}
                alt={
                  this.props.content.image_caption
                    ? this.props.content.image_caption
                    : this.props.content.title
                }
              />
            )}
            <div className="akteur-text">
              <h1>{this.props.content.title}</h1>
              {this.props.content.funktion && (
                <strong>{this.props.content.funktion}</strong>
              )}
              {this.props.content.text && !__SERVER__ && (
                <>
                  <p
                    className="akteur-richtext"
                    dangerouslySetInnerHTML={{
                      __html: this.props.content.text.data,
                    }}
                  />
                </>
              )}
              {this.props.content.email && (
                <div>
                  {!this.state.showContactForm && (
                    <Button
                      className="branded"
                      primary
                      icon
                      size="large"
                      labelPosition="left"
                      onClick={this.handleShowForm}
                    >
                      <Icon className="fontawesome" name="envelope" />
                      <Button.Content>Kontakt aufnehmen</Button.Content>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          {this.state.showContactForm && (
            <AkteurContact
              emailToName={this.props.content.title}
              emailToAddress={this.props.content.email}
            />
          )}
        </div>
      </Container>
    );
  }
}

export default AkteurView;
