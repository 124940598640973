/**
 * Suggest JobOffers.
 * @module actions/matchingTool/jobOfferSuggest
 */

import {
  compact,
  concat,
  isArray,
  join,
  map,
  pickBy,
  toPairs,
  identity,
} from 'lodash';

import { SUGGEST_JOB_OFFERS } from '../../constants/ActionTypes';

export function suggestJobOffers(options, subrequest = null) {
  let queryArray = [];
  options = pickBy(options, identity);
  const arrayOptions = pickBy(options, (item) => isArray(item));
  queryArray = concat(
    queryArray,
    options
      ? join(
          map(toPairs(pickBy(options, (item) => !isArray(item))), (item) => {
            return join(item, '=');
          }),
          '&',
        )
      : '',
  );

  queryArray = concat(
    queryArray,
    arrayOptions
      ? join(
          map(pickBy(arrayOptions), (item, key) =>
            join(
              item.map((value) => `${key}:list=${value}`),
              '&',
            ),
          ),
          '&',
        )
      : '',
  );

  const querystring = join(compact(queryArray), '&');

  return {
    type: SUGGEST_JOB_OFFERS,
    subrequest,
    request: {
      op: 'get',
      path: `@job-offer-suggest${querystring ? `?${querystring}` : ''}`,
    },
  };
}
