/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */
export const GET_HOMEPAGE_THEMENWELTEN = 'GET_HOMEPAGE_THEMENWELTEN';
export const GET_HOMEPAGE_SPRACHMODULE = 'GET_HOMEPAGE_SPRACHMODULE';
export const GET_HOMEPAGE_NACHRICHTEN = 'GET_HOMEPAGE_NACHRICHTEN';
export const GET_PROFILE = 'GET_PROFILE';
export const EMAIL_SEND = 'EMAIL_SEND';
export const NO_CURRENT_USER = 'NO_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SORT_AKTEURE = 'SORT_AKTEURE';
export const EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION';
export const UPDATE_COOKIE_CONSENT = 'UPDATE_COOKIE_CONSENT';
export const SEARCH_JOB_OFFERS = 'SEARCH_JOB_OFFERS';
export const RESET_SEARCH_JOB_OFFERS = 'RESET_SEARCH_JOB_OFFERS';
export const GET_JOB_OFFER_CATEGORIES = 'GET_JOB_OFFER_CATEGORIES';
export const SUGGEST_JOB_OFFERS = ' SUGGEST_JOB_OFFERS';
