/**
 * Anontools component.
 * @module components/theme/Anontools/Anontools
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import config from '@plone/volto/registry';

/**
 * Anontools container class.
 * @class Anontools
 * @extends Component
 */
class Anontools extends Component {
  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
    content: {
      '@id': null,
    },
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const returnParameter = this.props.content
      ? `?return_url=${this.props.content['@id'].replace(
          config.settings.apiPath,
          '',
        )}`
      : '';
    const loginUrl = `/login${returnParameter}`;
    return (
      !this.props.token && (
        <div className="button-wrapper">
          <Link className="item" to={loginUrl}>
            <Icon name="chevron right" size="small" className="fontawesome" />{' '}
            Anmelden
          </Link>
          <Button
            id="login-button"
            as={Link}
            to="/register"
            content="Registrieren"
            icon="sign in"
            labelPosition="left"
            color="blue"
          />
        </div>
      )
    );
  }
}

/*
render() {
  return (
    !this.props.token && (
      <List floated="right" horizontal>
        <Link className="item" to="/login">
          <FormattedMessage id="Log in" defaultMessage="Log in" />
        </Link>
      </List>
    )
  );
}
*/

export default compose(
  injectIntl,
  connect((state) => ({
    token: state.userSession.token,
    content: state.content.data,
  })),
)(Anontools);
