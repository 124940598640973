/**
 * MethodenkofferAlbum view component.
 * @module components/theme/View/Listings/MethodenkofferAlbum
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, List, Image } from 'semantic-ui-react';

/**
 * MethodenkofferAlbum component func.
 * @function MethodenkofferAlbum
 * @param {array} items Items object.
 * @returns {string} Markup of the component.
 */
const MethodenkofferAlbum = ({ items, clickable }) => (
  <Card.Group stackable doubling itemsPerRow={3}>
    {items.map((item) => (
      <Card
        as={clickable ? Link : null}
        to={clickable ? item.url : null}
        title={item.title}
        key={item.url}
      >
        <div className="methodenkoffer-image-wrapper">
          {item.image && (
            <Image
              src={
                item.image['content-type'] === 'image/svg+xml'
                  ? `${item['@id']}/@@download/image`
                  : item.image.scales.preview.download
              }
              alt={item.image_caption ? item.image_caption : item.title}
            />
          )}
        </div>
        <Card.Content>
          <Card.Header content={item.title} />
          <Card.Description>
            <List as="ul" relaxed="very">
              {item.items.map((subchild) => (
                <List.Item
                  as="li"
                  className="item"
                  title={subchild.description}
                  key={subchild.url}
                >
                  <List.Description
                    as={Link}
                    to={subchild.url}
                    content={subchild.title}
                  />
                </List.Item>
              ))}
            </List>
          </Card.Description>
        </Card.Content>
      </Card>
    ))}
    {/* Workaround while Flexbox 2.0 doesn't implement last row alignment */}
    {[...Array(3)].map((e, i) => (
      <Card key={i} />
    ))}
  </Card.Group>
);
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
MethodenkofferAlbum.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.object,
      '@type': PropTypes.string,
    }),
  ).isRequired,
  clickable: PropTypes.bool,
};

MethodenkofferAlbum.defaultProps = {
  clickable: true,
};

export default MethodenkofferAlbum;
