/**
 * ThemenweltView view component.
 * @module components/theme/View/ThemenweltView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

import ListingView from './ListingView';
import ThemenweltContent from './Listings/ThemenweltContent';
import ThemenweltList from './Listings/ThemenweltList';

/**
 * Document view component class.
 * @function ThemenweltView
 * @param {object} props Props object.
 * @returns {string} Markup of the component.
 */
const ThemenweltView = (props) => (
  <div className="view-wrapper">
    <Container as="section" id="content-core">
      <ListingView
        {...props}
        listingView={ThemenweltList}
        contentView={ThemenweltContent}
      />
    </Container>
  </div>
);

ThemenweltView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ThemenweltView;
