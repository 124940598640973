/**
 * MethodenkofferView view component.
 * @module components/theme/View/MethodenkofferView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { License } from '../../components';

import AlbumView from './AlbumView';
import MethodenkofferContent from './Albums/MethodenkofferContent';
import MethodenkofferAlbum from './Albums/MethodenkofferAlbum';

/**
 * MethodenkofferView component class.
 * @function MethodenkofferView
 * @param {object} props Props object.
 * @returns {string} Markup of the component.
 */
const MethodenkofferView = (props) => (
  <div className="methodenkoffer ui container">
    <AlbumView
      {...props}
      clickable={false}
      contentView={MethodenkofferContent}
      albumView={MethodenkofferAlbum}
    />
    {props.content.license && <License license={props.content.license} />}
  </div>
);

MethodenkofferView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default MethodenkofferView;
