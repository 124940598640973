import { GET_PROFILE } from '../../constants/ActionTypes';

/**
 * Get user function
 * @function getProfile
 * @param {string} id User id
 * @returns {Object} Get user action
 */
export function getProfile(id) {
  return {
    type: GET_PROFILE,
    request: {
      op: 'get',
      path: `/@users/${id}`,
    },
  };
}
