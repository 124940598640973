import React, { useEffect, useState } from 'react';
import { Grid, Button, Container } from 'semantic-ui-react';
// import Avatar from './Profile_avatar.png';
// import exampleAvatar from './example_avatar.jpeg';
import { parse, format } from 'date-fns';
import superagent from 'superagent';

const DiscourseBody = (props) => {
  const [latestPost, setLatestPost] = useState([]);

  //eslint-disable-next-line
  const example = [
    {
      id: 550,
      display_username: 'Max Mustermann',
      reads: 50,
      created_at: '2020-12-08T08:32:56.692Z',
      raw:
        '![](upload://yY4Qkx0Ecmf2IzMWYEkJ0X60eTL.jpeg)\n\nAm Samstag, den  **19. Juni 2021**, beim 6.  **#WelcomeCamp**  lautet das Motto  **Die perfekte Welt** . Es geht um Austausch, Aufbruch und das Erreichen unserer gemeinsamen Visionen.\n\nAufgrund der Pandemie ist Europas größtes Barcamp zu Integration ',
      avatar_template:
        'https://www.vhs-ehrenamtsportal.de/community/user_avatar/vhs-ehrenamtsportal.de/tobiasboenemann/{size}/185_2.png',
    },
    {
      id: 40,
      display_username: 'Maxime Musterfrau',
      reads: 23,
      created_at: '2020-11-08T08:32:56.692Z',
      raw:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ',
      avatar_template:
        'https://www.vhs-ehrenamtsportal.de/community/user_avatar/vhs-ehrenamtsportal.de/tobiasboenemann/{size}/185_2.png',
    },
    {
      id: 550,
      display_username: 'Max-Emil Mustermann-Adler',
      reads: 50,
      created_at: '2020-12-08T08:32:56.692Z',
      raw:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ',
      avatar_template:
        'https://www.vhs-ehrenamtsportal.de/community/user_avatar/vhs-ehrenamtsportal.de/tobiasboenemann/{size}/185_2.png',
    },
    {
      id: 550,
      display_username: 'Max Mustermann',
      reads: 50,
      created_at: '2020-12-08T08:32:56.692Z',
      raw:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ',
      avatar_template:
        'https://www.vhs-ehrenamtsportal.de/community/user_avatar/vhs-ehrenamtsportal.de/tobiasboenemann/{size}/185_2.png',
    },
  ];

  const baseUrl =
    process.env.RAZZLE_DISCOURSE_API_PATH ||
    'https://vhs-ehrenamtsportal.de/community';

  useEffect(() => {
    superagent
      .get(`${baseUrl}/posts.json`)
      .set('accept', 'json')
      .end((error, response) => {
        if (response?.status === 200) {
          setLatestPost(response.body.latest_posts.slice(0, 4));
        }
      });
    //eslint-disable-next-line
  }, []);

  var deLocale = require('date-fns/locale/de');

  return (
    <>
      <Container>
        <h3 className="beitraege">Beiträge aus der Community</h3>
        <Grid stackable columns="equal">
          {latestPost.map((item) => {
            return (
              <Grid.Column width={3}>
                <Grid.Row className="image">
                  <div className="image-wrapper">
                    <img
                      src={item?.avatar_template?.replace('{size}', '240')}
                      // src={exampleAvatar}
                      className="profile-image"
                      alt={`avatar of ${item?.display_username}`}
                    />
                    <div className="user information">
                      <p className="date">
                        {format(parse(item?.created_at), 'DD. MMMM YYYY', {
                          locale: deLocale,
                        })}
                      </p>
                      <p className="name">{item?.display_username}</p>
                    </div>
                  </div>
                </Grid.Row>
                <Grid.Row className="content">
                  <p className="text">
                    {item?.raw
                      .replace(/!\[\]\(.{0,}\)/g, '')
                      .replace(/\*{2,2}/g, '')}
                  </p>
                  {/* <div
                    dangerouslySetInnerHTML={{ _html: example.cooked }}
                  ></div> */}
                </Grid.Row>
              </Grid.Column>
            );
          })}
          <div className="button-wrapper">
            <a href="/community" alt="discourse">
              <Button className="branded blue" to="/community">
                ALLE BEITRÄGE
              </Button>
            </a>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default DiscourseBody;
