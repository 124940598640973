import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Autosuggest from 'react-autosuggest';
import superagent from 'superagent';
import cx from 'classnames';

const GeoSuggest = forwardRef(
  (
    {
      onSelect,
      onClear,
      placeholder = 'Ort Eingeben',
      lang = 'EN',
      country,
      data,
    },
    ref,
  ) => {
    const [suggestions, setSuggestions] = useState([]);
    const [text, setText] = useState(data?.job_offer_location_city_name || '');
    const [focused, setFocused] = useState(false);

    useImperativeHandle(ref, () => ({
      clearInput() {
        setText('');
      },
    }));

    const onSuggestionsFetchRequested = (query) => {
      if (isInteger(query.value[0])) {
        superagent
          .get(
            `https://secure.geonames.org//postalCodeSearch?postalcode_startsWith=${
              query.value
            }&username=jackahl&type=json&maxRows=5${
              country && `&country=${country}`
            }&lang=${lang}`,
          )
          .end((error, response) => {
            if (!error && response) {
              setSuggestions(response.body.postalCodes);
            } else {
              setSuggestions([]);
            }
          });
      } else {
        superagent
          .get(
            `https://secure.geonames.org//postalCodeSearch?placename_startsWith=${query.value}&username=jackahl&type=json&maxRows=5&country=DE&lang=${lang}`,
          )
          .end((error, response) => {
            if (!error && response) {
              setSuggestions(response.body.postalCodes);
            } else {
              setSuggestions([]);
            }
          });
      }
    };
    const onSuggestionsClearRequested = () => {
      setSuggestions([]);
    };
    const getSuggestionValue = (suggestion) => suggestion;
    const renderSuggestion = (suggestion) => {
      return (
        <span className="suggestion">
          {`
        ${suggestion.postalCode} - ${suggestion.placeName}
        `}
        </span>
      );
    };

    const onBlur = () => {
      setFocused(false);
    };
    const onFocus = () => {
      setFocused(true);
    };

    const onChange = (event, { newValue, method }) => {
      typeof newValue === 'string'
        ? setText(newValue)
        : setText(newValue.placeName);
    };

    const onSuggestionSelected = (suggestion, suggestionValue) => {
      onSelect(suggestionValue.suggestion);
      setText(
        `${suggestionValue.suggestion.postalCode} - ${suggestionValue.suggestion.placeName}`,
      );
    };

    const inputProps = {
      placeholder: placeholder,
      value: text ? text : '',
      onChange: onChange,
      onFocus: onFocus,
      onBlur: onBlur,
      onKeyDown: null,
    };

    const isInteger = (value) => {
      return /^\d+$/.test(value);
    };

    return (
      <div className={cx('geo-suggest', { focused })}>
        <Autosuggest
          suggestions={suggestions || []}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
        />
      </div>
    );
  },
);

export default GeoSuggest;
