import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

if (__CLIENT__) {
  ReactGA.initialize([
    {
      trackingId: 'G-JDR760M411',
      gaOptions: {
        anonymizeIp: true,
      },
      gtagOptions: {},
    },
  ]);
}

const useGoogleAnalytics = () => {
  let location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
};

export default useGoogleAnalytics;
