/**
 * SprachmoduleView view component.
 * @module components/theme/View/SprachmoduleView
 */

import React from 'react';
import PropTypes from 'prop-types';

import AlbumView from './AlbumView';
import SprachModuleAlbum from './Albums/SprachModuleAlbum';

/**
 * SprachmoduleView component func.
 * @function SprachmoduleView
 * @param {object} props Props object.
 * @returns {string} Markup of the component.
 */
const SprachmoduleView = (props) => (
  <AlbumView {...props} albumView={SprachModuleAlbum} />
);

SprachmoduleView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default SprachmoduleView;
