/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * Form component.
 * @module components/manage/Form/Form
 */

import { Toast } from '@plone/volto/components';
import { difference, FormValidation, messages } from '@plone/volto/helpers';
import { keys, mapValues, pickBy, cloneDeep } from 'lodash';
import Select from 'react-select';
import isBoolean from 'lodash/isBoolean';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Container,
  Form as UiForm,
  Input,
  TextArea,
  Dropdown,
  Grid,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import GeoSuggest from '../GeoSuggest/GeoSuggest';
import config from '@plone/volto/registry';
import JobOfferImageUploadWidget from './JobOfferImageUploadWidget';
import harstallProfile from './harstall.jpg';
import { getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL, Helmet } from '@plone/volto/helpers';

/**
 * AddJobOfferForm container class.
 * @class AddJobOfferForm
 * @extends Component
 */
class AddJobOfferForm extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    schema: PropTypes.shape({
      fieldsets: PropTypes.arrayOf(
        PropTypes.shape({
          fields: PropTypes.arrayOf(PropTypes.string),
          id: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
      properties: PropTypes.objectOf(PropTypes.any),
      definitions: PropTypes.objectOf(PropTypes.any),
      required: PropTypes.arrayOf(PropTypes.string),
    }),
    formData: PropTypes.objectOf(PropTypes.any),
    pathname: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    submitLabel: PropTypes.string,
    resetAfterSubmit: PropTypes.bool,
    isEditForm: PropTypes.bool,
    isAdminForm: PropTypes.bool,
    title: PropTypes.string,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    loading: PropTypes.bool,
    hideActions: PropTypes.bool,
    description: PropTypes.string,
    visual: PropTypes.bool,
    blocks: PropTypes.arrayOf(PropTypes.object),
    requestError: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    formData: null,
    onSubmit: null,
    onCancel: null,
    submitLabel: null,
    resetAfterSubmit: false,
    isEditForm: false,
    isAdminForm: false,
    title: null,
    description: null,
    error: null,
    loading: null,
    hideActions: false,
    visual: false,
    blocks: [],
    pathname: '',
    schema: {},
    requestError: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Form
   */
  constructor(props) {
    super(props);
    let { formData } = props;
    this.vocabBaseUrl =
      props.schema?.properties.job_offer_category.items.vocabulary['@id'];
    if (!props.isEditForm) {
      // It's a normal (add form), get defaults from schema
      formData = {
        ...mapValues(props.schema?.properties, 'default'),
        ...formData,
        time_units: 'week',
      };
    }

    this.state = {
      formData,
      initialFormData: cloneDeep(formData),
      errors: {},
      isClient: false,
    };
  }

  /**
   * On updates caused by props change
   * if errors from Backend come, these will be shown to their corresponding Fields
   * also the first Tab to have any errors will be selected
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    let { requestError } = this.props;
    let errors = {};
    let activeIndex = 0;

    if (requestError && prevProps.requestError !== requestError) {
      errors = FormValidation.giveServerErrorsToCorrespondingFields(
        requestError,
      );
      activeIndex = FormValidation.showFirstTabWithErrors({
        errors,
        schema: this.props.schema,
      });

      this.setState({
        errors,
        activeIndex,
      });
    }
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.setState({ isClient: true });
    this.props.getVocabulary({ vocabNameOrURL: this.vocabBaseUrl });
  }

  // UNSAFE_componentWillMount() {
  //   this.props.listJobOfferCategories();
  // }

  /**
   * Change field handler
   * Remove errors for changed field
   * @method onChangeField
   * @param {string} id Id of the field
   * @param {*} value Value of the field
   * @returns {undefined}
   */
  onChangeField = (id, value) => {
    this.setState((prevState) => {
      const { errors, formData } = prevState;
      delete errors[id];
      return {
        errors,
        formData: {
          ...formData,
          // We need to catch also when the value equals false this fixes #888
          [id]:
            value || (value !== undefined && isBoolean(value)) ? value : null,
        },
      };
    });
  };

  /**
   * Submit handler also validate form and collect errors
   * @method onSubmit
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    const errors = FormValidation.validateFieldsPerFieldset({
      schema: this.props.schema,
      formData: this.state.formData,
      formatMessage: this.props.intl.formatMessage,
    });

    if (keys(errors).length > 0) {
      const activeIndex = FormValidation.showFirstTabWithErrors({
        errors,
        schema: this.props.schema,
      });
      this.setState(
        {
          errors,
          activeIndex,
        },
        () => {
          Object.keys(errors).forEach((err) =>
            toast.error(
              <Toast error title={err} content={errors[err].join(', ')} />,
            ),
          );
        },
      );
    } else {
      // Get only the values that have been modified (Edit forms), send all in case that
      // it's an add form
      if (this.props.isEditForm) {
        this.props.onSubmit(this.getOnlyFormModifiedValues());
      } else {
        this.props.onSubmit(this.state.formData);
      }
      if (this.props.resetAfterSubmit) {
        this.setState({
          formData: this.props.formData,
        });
      }
    }
  };

  /**
   * getOnlyFormModifiedValues handler
   * It returns only the values of the fields that are have really changed since the
   * form was loaded. Useful for edit forms and PATCH operations, when we only want to
   * send the changed data.
   * @method getOnlyFormModifiedValues
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  getOnlyFormModifiedValues = () => {
    const fieldsModified = Object.keys(
      difference(this.state.formData, this.state.initialFormData),
    );
    return {
      ...pickBy(this.state.formData, (value, key) =>
        fieldsModified.includes(key),
      ),
      ...(this.state.formData['@static_behaviors'] && {
        '@static_behaviors': this.state.formData['@static_behaviors'],
      }),
    };
  };

  onSelectLocation = (place) => {
    this.onChangeField('job_offer_location_city_name', place.placeName);
    this.onChangeField('job_offer_location_lat', place.lat);
    this.onChangeField('job_offer_location_lon', place.lng);
  };

  onClearLocation = (place, placename) => {
    this.onChangeField('job_offer_location_city_name');
    this.onChangeField('job_offer_location_lat');
    this.onChangeField('job_offer_location_lon');
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const time_units = this.props.schema.properties.time_units.choices.map(
      (choice) => {
        return {
          key: choice[0],
          text: `${choice[1] !== 'Einmalig' ? 'pro' : ''} ${choice[1]}`,
          value: choice[0],
        };
      },
    );

    return (
      <Container>
        <Helmet title="Angebot einstellen" />
        <section className="intro-area">
          <div>
            <h1>Angebot anlegen</h1>
            <p>Hier können Sie Ihr Angebot erstellen.</p>
          </div>
          <div className="contact-box">
            <div>
              <p>
                <b>Sie haben Fragen?</b> <br />
                Kontaktieren Sie mich!
              </p>
              <p>
                <b>Alexandra Harstall</b>
                <br />
                Tel.: 0228-97569407
                <br />
                <a href="mailto:harstall@dvv-vhs.de">harstall@dvv-vhs.de</a>
              </p>
            </div>
            <div className="profile-image-wrapper">
              <img src={harstallProfile} alt="Profilbild Alexandra Harstall" />
            </div>
          </div>
        </section>
        <UiForm
          method="post"
          onSubmit={this.onSubmit}
          error={keys(this.state.errors).length > 0}
          className={config.settings.verticalFormTabs ? 'vertical-form' : ''}
        >
          <section className="section-title">
            <Input
              id="field-title"
              name="title"
              value={this.state.formData.title}
              onChange={(name, value) =>
                this.onChangeField(value.name, value.value)
              }
              placeholder="Titel des Angebots"
              required={true}
              key="title"
            />
          </section>
          <section className="image-upload">
            <p>
              Laden Sie ein passendes Bild hoch. Das Bild muss das Angebot gut
              beschreiben und mindestens 1440 x 400 px groß sein.
            </p>

            <JobOfferImageUploadWidget
              id="field-image"
              name="image"
              value={this.state.initialFormData.image}
              onChange={(name, value) => {
                this.onChangeField('image', value);
              }}
              title="Image"
              onBlur={() => {}}
              key="image"
            />
          </section>
          <Grid>
            <Grid.Row as="section" className="base-data">
              <Grid.Column tablet={6} computer={6} mobile={12}>
                <GeoSuggest
                  lang="DE"
                  onSelect={this.onSelectLocation}
                  onClear={this.onClearLocation}
                  placeholder="Einsatzort eingeben"
                  country="DE"
                  data={{
                    job_offer_location_city_name: this.state.formData
                      .job_offer_location_city_name,
                    job_offer_location_lat: this.state.formData
                      .job_offer_location_lat,
                    job_offer_location_lon: this.state.formData
                      .job_offer_location_lon,
                  }}
                />
                <div className="time-field">
                  <Input
                    id="field-time_amount"
                    name="time_amount"
                    value={this.state.formData.time_amount}
                    onChange={(name, value) =>
                      this.onChangeField(value.name, value.value)
                    }
                    placeholder="Stunden"
                    required={true}
                    key="time_amount"
                    type="number"
                  />
                  <Dropdown
                    selection
                    id="field-time_units"
                    name="time_units"
                    text={this.state.formData.time_units.title}
                    onChange={(name, value) =>
                      this.onChangeField(value.name, value.value)
                    }
                    required={true}
                    defaultValue={time_units[1].value}
                    // simple
                    options={time_units}
                    key="time_units"
                  />
                </div>
              </Grid.Column>
              <Grid.Column tablet={6} computer={6} mobile={12}>
                <div className="job_offer_categories-field">
                  {this.props.categories.items?.length > 0 && (
                    <Select
                      options={this.props.categories.items}
                      isMulti
                      value={this.state.formData.job_offer_categories.map(
                        (category) => {
                          return {
                            label: category.title,
                            value: category.token,
                          };
                        },
                      )}
                      isLoading={this.props.categories.loading}
                      onChange={(selectValue) => {
                        this.onChangeField(
                          'job_offer_categories',
                          selectValue.map((selection) => {
                            return {
                              title: selection.label,
                              token: selection.value,
                            };
                          }),
                        );
                      }}
                    />
                  )}
                  <label
                    for="field-job_offer_categories"
                    className="field-job_offer_categories-label"
                  >
                    z.B. Lerncafé, Alltagsbegleitung, Mehrgenerationenhaus
                  </label>
                </div>
                <p>Das richtige Einsatzfeld nicht gefunden?</p>
                <Input
                  id="field-job_offer_categories_custom"
                  name="job_offer_categories_custom"
                  value={this.state.formData.job_offer_categories_custom}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Neue Einsatzfelder vorschlagen"
                  key="job_offer_categories_custom"
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row as="section" className="description-section">
              <Grid.Column tablet={6} computer={6} mobile={12}>
                <p>
                  Schreiben Sie hier ein paar ansprechende Wörter zur Tätigkeit
                  und zu Ihrer Organisation und wecken Sie das Interesse von
                  potentiellen Ehrenamtlichen. Warum sollten sich
                  Interessent*innen ausgerechnet bei Ihnen engagieren?
                </p>
                <TextArea
                  id="field-job_offer_description"
                  name="job_offer_description"
                  value={this.state.formData.job_offer_description}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Beschreibung des Einsatzfeldes"
                  required={true}
                  key="job_offer_description"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row as="section" className="requirements-section">
              <Grid.Column tablet={6} computer={6} mobile={12}>
                <p>
                  Was sollte der*die Ehrenamtliche mitbringen?
                  <br /> Mind. 2, max. 4 Anforderungen eingeben:
                </p>
                <div className="req-dot" />
                <Input
                  id="field-requirement_one"
                  className="required"
                  name="requirement_one"
                  value={this.state.formData.requirement_one}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Anforderung eingeben"
                  required={true}
                  key="requirement_one"
                />
                <div className="req-dot" />
                <Input
                  id="field-requirement_two"
                  className="required"
                  name="requirement_two"
                  value={this.state.formData.requirement_two}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Anforderung eingeben"
                  required={true}
                  key="requirement_two"
                />
                <Input
                  id="field-requirement_three"
                  name="requirement_three"
                  value={this.state.formData.requirement_three}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Anforderung eingeben"
                  key="requirement_three"
                />
                <Input
                  id="field-requirement_four"
                  name="requirement_four"
                  value={this.state.formData.requirement_four}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Anforderung eingeben"
                  key="requirement_four"
                />
              </Grid.Column>
              <Grid.Column tablet={6} computer={6} mobile={12}>
                <p>
                  Unser Angebot
                  <br /> Mind. 2, max. 4 Textfelder ausfüllen:
                </p>
                <div className="req-dot" />
                <Input
                  id="field-benefit_one"
                  className="required"
                  name="benefit_one"
                  value={this.state.formData.benefit_one}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Textfeld ausfüllen"
                  required={true}
                  key="benefit_one"
                />
                <div className="req-dot" />
                <Input
                  id="field-benefit_two"
                  className="required"
                  name="benefit_two"
                  value={this.state.formData.benefit_two}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Textfeld ausfüllen"
                  required={true}
                  key="benefit_two"
                />
                <Input
                  id="field-benefit_three"
                  name="benefit_three"
                  value={this.state.formData.benefit_three}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Textfeld ausfüllen"
                  key="benefit_three"
                />
                <Input
                  id="field-benefit_four"
                  name="benefit_four"
                  value={this.state.formData.benefit_four}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Textfeld ausfüllen"
                  key="benefit_four"
                />
              </Grid.Column>
            </Grid.Row>
            <h2>Kontaktdaten</h2>
            <Grid.Row as="section" className="contact-section">
              <Grid.Column width={4}>
                <div className="req-dot" />
                <Input
                  id="field-name"
                  name="name"
                  value={this.state.formData.name}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Vor- und Nachname"
                  required={true}
                  key="name"
                />
                <div className="req-dot" />
                <Input
                  id="field-email"
                  name="email"
                  value={this.state.formData.email}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="E-Mail-Adresse"
                  required={true}
                  key="email"
                />
                <div className="req-dot" />
                <Input
                  id="field-phone"
                  name="phone"
                  value={this.state.formData.phone}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Telefonnummer"
                  required={true}
                  key="phone"
                />{' '}
                <Input
                  id="field-website"
                  name="website"
                  value={this.state.formData.website}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Webseite"
                  required={false}
                  key="website"
                />
                <div className="req-dot" />
                <Input
                  id="field-bussiness_name"
                  name="bussiness_name"
                  value={this.state.formData.bussiness_name}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="Name der Organisation"
                  required={true}
                  key="bussiness_name"
                />
                <div className="req-dot" />
                <Input
                  id="field-bussiness_address"
                  name="bussiness_address"
                  value={this.state.formData.bussiness_address}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  required={true}
                  key="bussiness_address"
                  placeholder="Straße und Hausnummer"
                />
                <div className="req-dot" />
                <Input
                  id="field-bussiness_city"
                  name="bussiness_city"
                  value={this.state.formData.bussiness_city}
                  onChange={(name, value) =>
                    this.onChangeField(value.name, value.value)
                  }
                  placeholder="PLZ und Ort"
                  required
                  key="bussiness_city"
                  icon="required"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Button
            primary
            type="submit"
            aria-label={
              this.props.submitLabel
                ? this.props.submitLabel
                : this.props.intl.formatMessage(messages.save)
            }
            title={
              this.props.submitLabel
                ? this.props.submitLabel
                : this.props.intl.formatMessage(messages.save)
            }
            loading={this.props.loading}
          >
            {this.props.isEditForm
              ? 'Angebot aktualisieren'
              : 'Angebot einreichen'}
          </Button>
          <Link
            className="ui button primary red"
            to={
              this.props.isEditForm
                ? flattenToAppURL(this.props.formData['@id'])
                : '/engagementfinder'
            }
          >
            Abbrechen
          </Link>
        </UiForm>
      </Container>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, props) => {
      const vocabBaseUrl =
        props.schema?.properties.job_offer_category.items.vocabulary['@id'];
      const categories = state.vocabularies[vocabBaseUrl];

      return { categories: { ...categories } };
    },
    { getVocabulary },
  ),
)(AddJobOfferForm);
