import React from 'react';
import Newsletter from '../Homepage/Newsletter';

const View = () => {
  return (
    <div className="block newsletter">
      <Newsletter></Newsletter>
    </div>
  );
};

export default View;
