/**
 * Akteure actions.
 * @module actions/akteure/akteure
 */

import { SORT_AKTEURE } from '../../constants/ActionTypes';

/**
 * Get akteure.
 * @function sortAkteure
 * @returns {Object} Get akteure action.
 */
export default function sortAkteure() {
  return (dispatch, getState) => {
    const { items } = getState().search;
    dispatch({
      type: SORT_AKTEURE,
      items,
    });
  };
}
