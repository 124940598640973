import React from 'react';
import { TextBody } from '@kitconcept/volto-blocks/components';

const View = ({ data, block }) => {
  return (
    <div className="block text centered">
      <TextBody data={data} block={block} dataName="text" />
    </div>
  );
};

export default View;
