/**
 * SprachModuleAlbum view component.
 * @module components/theme/View/Listings/SprachModuleAlbum
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Card, Icon, Image, Statistic } from 'semantic-ui-react';
import { uniqueId } from 'lodash';

import { Youtube } from '../../../components';

/**
 * SprachModuleAlbum component class.
 * @function SprachModuleAlbum
 * @param {array} items Items object.
 * @returns {string} Markup of the component.
 */
class SprachModuleAlbum extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        '@type': PropTypes.string,
      }),
    ).isRequired,
    clickable: PropTypes.bool,
    showDescription: PropTypes.bool,
  };

  static defaultProps = {
    clickable: true,
    showDescription: true,
  };

  /**
   *
   * @method render
   * @returns {string} markup
   */
  render() {
    return (
      <Card.Group stackable doubling itemsPerRow={3}>
        {this.props.items.map((item) => (
          <Card title={item.title} key={item.url}>
            <div className="image-wrapper">
              {item.image && item['@type'] !== 'Video' && (
                <Image
                  src={
                    item.image.width > 0
                      ? item.image.scales.preview.download
                      : item.image.download
                  }
                  alt={item.image_caption ? item.image_caption : item.title}
                />
              )}
              {item.youtube_embed_url && item['@type'] === 'Video' && (
                <div>
                  <Youtube url={item.youtube_embed_url} />
                </div>
              )}
            </div>
            <Card.Content>
              <Card.Header content={item.title} textAlign="center" />
              {this.props.showDescription &&
                item['@type'] !== 'RichTextTile' && (
                  <Card.Description
                    content={
                      item.description_sprachmodul_overview || item.description
                    }
                    textAlign="center"
                  />
                )}
              {item.text && item['@type'] === 'RichTextTile' && (
                <Card.Description>
                  <div
                    style={{ marginBottom: '20px', textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: item.text.data }}
                  />
                </Card.Description>
              )}
              {item['@type'] === 'RichTextTile' &&
              item.link_url &&
              item.link_text === 'zum Methodenkoffer' ? (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className="branded blue"
                    as={Link}
                    to="/methodenkoffer/mein-methodenkoffer"
                  >
                    {item.link_text}
                  </Button>
                </div>
              ) : (
                item['@type'] === 'RichTextTile' &&
                item.link_url && (
                  <div style={{ textAlign: 'center' }}>
                    <Button className="branded blue" as={Link} to={item.url}>
                      {item.link_text}
                    </Button>
                  </div>
                )
              )}
              {item['@type'] === 'Sprachmodul' && (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    as={Link}
                    to={item.url}
                    className="branded blue"
                    style={{ marginTop: '20px' }}
                  >
                    Zum Sprachmodul
                  </Button>
                </div>
              )}
              {item['@type'] === 'Sprachmodul' && (
                <Card.Content extra>
                  <Statistic size="mini" horizontal>
                    <Statistic.Value>
                      <Icon name="user" color="red" />
                      <span>{item.items_total.lerneinheit} Lerneinheiten</span>
                    </Statistic.Value>
                    <Statistic.Value>
                      <Icon
                        name="unordered list"
                        color="yellow"
                        className="fontawesome"
                      />{' '}
                      <span>{item.items_total.methodereference} Methoden</span>
                    </Statistic.Value>
                    <Statistic.Value>
                      <Icon name="comments" color="green" />
                      <span>{item.items_total.discussion_item}</span>
                    </Statistic.Value>
                  </Statistic>
                </Card.Content>
              )}
            </Card.Content>
          </Card>
        ))}
        {/* Workaround while Flexbox 2.0 doesn't implement last row alignment */}
        {[...Array(3)].map((e, i) => (
          <Card key={uniqueId(`sprachmoduleAlbum-${i}`)} />
        ))}
      </Card.Group>
    );
  }
}

export default SprachModuleAlbum;
