/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import homepageNachrichten from './homepage/nachrichten';
import homepageSprachmodule from './homepage/sprachmodule';
import homepageThemenwelten from './homepage/themenwelten';
import emailSend from './emailSend/emailSend';
import currentUser from './currentUser/currentUser';
import akteure from './akteure/akteure';
import profiles from './profiles/profiles';
import cookieConsent from './cookieConsent/cookieConsent';
import JobOfferSearch from './jobOfferSearch/JobOfferSearch';
import jobOfferCategories from './jobOfferSearch/JobOfferCategories';
import jobOfferSuggest from './jobOfferSearch/JobOfferSuggest';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  homepageNachrichten,
  homepageSprachmodule,
  homepageThemenwelten,
  emailSend,
  profiles,
  currentUser,
  akteure,
  cookieConsent,
  JobOfferSearch,
  jobOfferCategories,
  jobOfferSuggest,
};

export default reducers;
