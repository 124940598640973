import React, { useState } from 'react';
import { Button, Checkbox, Form, Container } from 'semantic-ui-react';
import { withCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { updateCookieConsent } from '../../actions';

const View = ({ isEditMode, cookies }) => {
  const dispatch = useDispatch();

  const [agreedGoogle, setAgreedGoogle] = useState(
    !!Number(cookies.get('confirm_ga')),
  );
  const [agreedYoutube, setAgreedYoutube] = useState(
    !!Number(cookies.get('confirm_youtube')),
  );
  const [agreedFacebook, setAgreedFacebook] = useState(
    !!Number(cookies.get('confirm_facebook')),
  );

  const confirmSelection = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    if (agreedGoogle) {
      cookies.set('confirm_ga', 1, {
        path: '/',
        expires: expiryDate,
      });
      dispatch(updateCookieConsent({ googleAnalytics: true }));
    } else {
      cookies.set('confirm_ga', 0, {
        path: '/',
        expires: expiryDate,
      });
      cookies.remove('_ga');
      cookies.remove('_gat');
      cookies.remove('_gid');
      dispatch(updateCookieConsent({ googleAnalytics: false }));
    }

    if (agreedFacebook) {
      cookies.set('confirm_facebook', 1, {
        path: '/',
        expires: expiryDate,
      });
      dispatch(updateCookieConsent({ facebook: true }));
    } else {
      cookies.remove('confirm_facebook');
      dispatch(updateCookieConsent({ facebook: false }));
    }

    if (agreedYoutube) {
      cookies.set('confirm_youtube', 1, {
        path: '/',
        expires: expiryDate,
      });
      dispatch(updateCookieConsent({ youtube: true }));
    } else {
      cookies.remove('confirm_youtube');
      dispatch(updateCookieConsent({ youtube: false }));
    }
    cookies.set('has_seen_cookie_modal', 1, { path: '/' });
  };

  return (
    <div className="cookie-block">
      <Container>
        <Form>
          <Form.Field>
            <Checkbox toggle label="Technisch notwendig" checked disabled />
          </Form.Field>
          <Form.Field>
            <Checkbox
              disabled={isEditMode}
              toggle
              label="Tracking"
              onChange={() => setAgreedGoogle(!agreedGoogle)}
              checked={agreedGoogle}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              disabled={isEditMode}
              toggle
              label="Youtube"
              onChange={() => setAgreedYoutube(!agreedYoutube)}
              checked={agreedYoutube}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              disabled={isEditMode}
              toggle
              label="Facebook"
              onChange={() => setAgreedFacebook(!agreedFacebook)}
              checked={agreedFacebook}
            />
          </Form.Field>
        </Form>
        <Button.Group>
          <Button className="branded blue" branded onClick={confirmSelection}>
            Speichern
          </Button>
        </Button.Group>
      </Container>
    </div>
  );
};

export default withCookies(View);
