/**
 * ThemenweltenAlbum view component.
 * @module components/theme/View/Listings/ThemenweltenAlbum
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Card, Image } from 'semantic-ui-react';
import { uniqueId } from 'lodash';
import circleRed from './circle-red.svg';
import circleYellow from './circle-yellow.svg';
import circleBlue from './circle-blue.svg';
import circleGreen from './circle-green.svg';

import { Youtube } from '../../../components';

/**
 * ThemenweltenAlbum component class.
 * @function ThemenweltenAlbum
 * @param {array} items Items object.
 * @returns {string} Markup of the component.
 */
class ThemenweltenAlbum extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        '@type': PropTypes.string,
      }),
    ).isRequired,
    clickable: PropTypes.bool,
    showDescription: PropTypes.bool,
  };

  static defaultProps = {
    clickable: true,
    showDescription: true,
  };

  /**
   *
   * @method render
   * @returns {string} markup
   */
  render() {
    return (
      <Card.Group stackable doubling itemsPerRow={3}>
        {this.props.items.map((item) => (
          <Card title={item.title} key={item.url}>
            <div className="image-wrapper">
              {item.image && item['@type'] !== 'Video' && (
                <Image
                  src={
                    item.image.width > 0
                      ? item.image.scales.preview.download
                      : item.image.download
                  }
                  alt={item.image_caption ? item.image_caption : item.title}
                />
              )}
              {item.youtube_embed_url && item['@type'] === 'Video' && (
                <div>
                  <Youtube url={item.youtube_embed_url} />
                </div>
              )}
            </div>
            <Card.Content>
              {item.cluster && (
                <div className="center aligned themenwelt-pretitle">
                  <img
                    src={
                      item.cluster === 'ehrenamt-gestalten'
                        ? circleRed
                        : item.cluster === 'ehrenamt-koordinieren'
                        ? circleBlue
                        : item.cluster === 'grundbildung-unterstuetzen'
                        ? circleGreen
                        : circleYellow
                    }
                    alt="circle"
                    title="circle"
                  />
                  <span>
                    {(() => {
                      switch (item.cluster.token) {
                        case 'ehrenamt-gestalten':
                          return 'Mein Ehrenamt gestalten';
                        case 'integration-gestalten':
                          return 'Integration gestalten';
                        case 'ehrenamt-koordinieren':
                          return 'Ehrenamt koordinieren';
                        case 'grundbildung-unterstuetzen':
                          return 'Grundbildung unterstützen';
                        default:
                          return 'Mein Ehrenamt gestalten';
                      }
                    })()}
                  </span>
                </div>
              )}
              <Card.Header content={item.title} textAlign="center" />
              {this.props.showDescription &&
                item['@type'] !== 'RichTextTile' && (
                  <Card.Description
                    content={
                      item.description_themenwelt_overview || item.description
                    }
                    textAlign="center"
                  />
                )}
              {this.props.showDescription && item['@type'] === 'RichTextTile' && (
                <Card.Description>
                  <div
                    style={{ marginBottom: '20px' }}
                    dangerouslySetInnerHTML={{ __html: item.text.data }}
                  />
                </Card.Description>
              )}
              {item['@type'] === 'RichTextTile' && item.link_url && (
                <div style={{ textAlign: 'center' }}>
                  <Button as={Link} to={item.url} className="branded blue">
                    {item.link_text}
                  </Button>
                </div>
              )}
              {item['@type'] === 'Themenwelt' && (
                <div style={{ textAlign: 'center' }}>
                  <Button as={Link} to={item.url} className="branded blue">
                    Zur Themenwelt
                  </Button>
                </div>
              )}
              {item.youtube_embed_url && item['@type'] === 'Video' && (
                <div style={{ textAlign: 'center' }}>
                  <Button as={Link} to={item.url} className="branded blue">
                    Mehr erfahren
                  </Button>
                </div>
              )}
            </Card.Content>
          </Card>
        ))}
        {/* Workaround while Flexbox 2.0 doesn't implement last row alignment */}
        {[...Array(3)].map((e, i) => (
          <Card key={uniqueId(`sprachmoduleAlbum-${i}`)} />
        ))}
      </Card.Group>
    );
  }
}

export default ThemenweltenAlbum;
