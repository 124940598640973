import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './sidebar';
import View from './View';

const Edit = (props) => {
  return (
    <>
      <View {...props} />
      <SidebarPortal selected={props.selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </>
  );
};

export default Edit;
