import React, { Component, Fragment } from 'react';
import superagent from 'superagent';
import * as Sentry from '@sentry/browser';

import FacebookPost from './FacebookPost';
import fbIcon from './fbicon.svg';

/**
 * Contact form class.
 * @class ContactForm
 * @extends Component
 */
export default class FacebookFeed extends Component {
  /**
   * Creates an instance of FacebookFeed.
   * @param {any} props Props
   * @memberof FacebookFeed
   */
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      nextPostsUrl: undefined,
      pageId: undefined,
      accessToken: `${props.appId}|${props.appSecret}`,
      requestError: false,
      batchSize: parseInt(this.props.batchSize, 10),
      hasError: false,
      errorInfo: null,
    };
    this.apiUrlBase = 'https://graph.facebook.com/v2.11';
    this.apiPostsRequestParams = {
      fields:
        'full_picture,id,from,name,message,created_time,story,' +
        'description,link,picture,object_id,type',
      limit: this.state.batchSize,
    };
  }

  /**
   *
   * @returns {any} null
   * @memberof FacebookFeed
   */
  componentWillMount() {
    const promise = this.apiRequest(`/${this.props.userName}`);
    promise
      .then((data) => {
        if (!data.id) {
          throw new Error('no data.id');
        }
        this.setState({ pageId: data.id });
        this.fetchPosts();
      })
      .catch((error) => {
        this.setError();
      });
  }

  /**
   *
   * @returns {any} null
   * @memberof FacebookFeed
   */
  setError() {
    this.setState({
      nextPostsUrl: undefined,
      requestError: true,
    });
  }

  /**
   * ComponentDidCatch
   * @method ComponentDidCatch
   * @param {string} error  The error
   * @param {string} info The info
   * @returns {undefined}
   */
  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, errorInfo: info });
    if (__CLIENT__) {
      if (window?.env?.RAZZLE_SENTRY_DSN || __SENTRY__?.SENTRY_DSN) {
        Sentry.captureException(error);
      }
    }
  }

  /**
   * @param {string} path Path
   * @param {string} params Params
   * @param {func} errorCallback Path
   * @returns {any} null
   * @memberof FacebookFeed
   */
  apiRequest = (path, params = {}, errorCallback) => {
    const encode = encodeURIComponent;
    params.access_token = this.state.accessToken;
    const queryString = Object.keys(params)
      .map((name) => {
        let value = params[name];
        if (name !== 'access_token') {
          value = encode(value);
        }
        return `${encode(name)}=${value}`;
      })
      .join('&');
    const url = `${this.apiUrlBase}/${path}?${queryString}`;
    return superagent
      .get(url)
      .set('Accept', 'application/json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Not ok');
        }
        return response.body;
      })
      .then((data) => {
        if (!data.error) {
          return data;
        }
        throw new Error('data.error');
      })
      .catch((error) => {
        errorCallback();
      });
  };

  /**
   *
   * @returns {any} null
   * @memberof FacebookFeed
   */
  fetchPosts() {
    const { pageId } = this.state;
    if (!pageId) {
      this.setError();
      return;
    }

    this.apiRequest(
      `/${pageId}/posts`,
      this.apiPostsRequestParams,
      this.setError,
    ).then((data) => {
      const allPosts = this.state.posts.concat(data.data);
      this.setState({
        posts: allPosts,
        nextPostsUrl: data.paging.next,
      });
    });
  }

  /**
   *
   * @returns {any} null
   * @memberof FacebookFeed
   */
  render() {
    return (
      <Fragment>
        <div
          className="facebook-header"
          style={{ backgroundImage: `url(${fbIcon})` }}
        >
          <span>Finden Sie uns auf Facebook</span>
        </div>
        <div className="facebook-feed-container">
          {this.state.hasError ? (
            <div style={{ display: 'none' }}>
              There was an error while displaying the feed
            </div>
          ) : (
            this.state.posts.map((post) => (
              <FacebookPost key={post.id} post={post} />
            ))
          )}
        </div>
      </Fragment>
    );
  }
}
