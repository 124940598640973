/**
 * Listing view component.
 * @module components/theme/View/ListingView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import DefaultList from './Listings/DefaultList';
import DefaultContent from './Listings/DefaultContent';

/**
 * Listing view component class.
 * @function ListingView
 * @extends Component
 */
class ListingView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.object,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string,
          '@type': PropTypes.string,
          description: PropTypes.string,
          review_state: PropTypes.string,
          title: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }).isRequired,
    customContent: PropTypes.bool,
    listingView: PropTypes.func,
    contentView: PropTypes.func,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    customContent: false,
    listingView: DefaultList,
    contentView: DefaultContent,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const ListComponent = this.props.listingView;
    const ContentComponent = this.props.contentView;

    if (
      this.props.listingView !== DefaultList &&
      this.props.contentView !== DefaultContent
    ) {
      return (
        <div>
          {/* TODO: We need to improve this with fragments in React 16 */}
          <ContentComponent content={this.props.content} />
          <ListComponent items={this.props.content.items} />
        </div>
      );
    }

    return (
      <div className="view-wrapper">
        <section id="content-core">
          <ContentComponent content={this.props.content} />
          <ListComponent items={this.props.content.items} />
        </section>
      </div>
    );
  }
}

export default injectIntl(ListingView);
