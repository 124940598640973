import React from 'react';
import PropTypes from 'prop-types';
import { SingleVideo } from '../../components';

/**
 * Contact form class.
 * @class ContactForm
 * @extends Component
 */
const FacebookMediaItem = ({ post }) => (
  <div className="facebook-media-item">
    {post.link && (
      <a href={post.link}>
        {(() => {
          if (
            post.type === 'link' ||
            post.type === 'image' ||
            post.type === 'photo'
          ) {
            if (post.full_picture || post.picture || post.photo) {
              return (
                <img
                  src={post.full_picture || post.picture || post.photo}
                  alt={post.name}
                />
              );
            }
            return <span>{post.name}</span>;
          }
          return null;
        })()}
        {post.type === 'video' &&
          (post.link.includes('youtube') || post.link.includes('youtu.be')) && (
            <SingleVideo url={post.link} />
          )}
      </a>
    )}
  </div>
);

FacebookMediaItem.propTypes = {
  post: PropTypes.shape({
    link: PropTypes.string,
    picture: PropTypes.string,
    full_picture: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default FacebookMediaItem;
