/**
 * Akteure reducer.
 * @module reducers/akteure
 */

import { filter } from 'lodash';

import { SORT_AKTEURE } from '../../constants/ActionTypes';

const initialState = {
  team: [],
  expertInnen: [],
  ehrenamtliche: [],
};

/**
 * Akteure reducer.
 * @function akteure
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function akteure(state = initialState, action = {}) {
  switch (action.type) {
    case SORT_AKTEURE:
      return {
        ...state,
        team: filter(action.items, (item) => item.subjects.includes('Team')),
        expertInnen: filter(action.items, (item) =>
          item.subjects.includes('ExpertInnen'),
        ),
        ehrenamtliche: filter(action.items, (item) =>
          item.subjects.includes('Ehrenamt'),
        ),
      };
    default:
      return state;
  }
}
