import React from 'react';
import HighlightBody from './HighlightBody';

const View = ({ data, id, render }) => {
  const teaserHeroBodyRender = render ? (
    <>{render(data, id)}</>
  ) : (
    <HighlightBody data={data} id={id} />
  );
  return <div className="block teaser">{teaserHeroBodyRender}</div>;
};

export default View;
