import React from 'react';
import man from '../../icons/man.svg';
import education from '../../icons/education.svg';
import { Icon } from '@plone/volto/components';
import cx from 'classnames';

const Wrapper = (props) => {
  return (
    <>
      <div
        className={cx('seperator-teaser-grid', {
          edu: props.data.variation === 'education',
        })}
      >
        {props.data.variation === 'man' ? (
          <Icon className="teaser-svg" size="120px" name={man} />
        ) : (
          <Icon className="teaser-svg" size="120px" name={education} />
        )}
      </div>
      <div className="wrapper-heading" style={{ minHeight: '30px' }}>
        {props.data?.title}
      </div>
      {props.children}
    </>
  );
};

export default Wrapper;
