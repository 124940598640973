import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FacebookMediaItem from './FacebookMediaItem';

/**
 * Contact form class.
 * @class ContactForm
 * @extends Component
 */
const FacebookPost = ({ post }) => {
  const userId = post.from.id;
  const userName = post.from.name;
  const profileUrl = `https://facebook.com/${userId}`;
  const profileImageUrl = `https://graph.facebook.com/${userId}/picture`;

  return (
    <div className="facebook-post">
      <div className="facebook-post-header">
        <div>
          <a href={profileUrl} className="facebook-post-author-image">
            <img alt={`${userName} avatar`} src={profileImageUrl} />
          </a>
        </div>
        <p className="facebook-post-author">
          <a href={profileUrl}>{userName}</a>
          <span>{moment.utc(post.created_time).local().calendar()}</span>
        </p>
      </div>
      <div className="facebook-post-content">
        <p className="facebook-post-message">{post.message}</p>
        <FacebookMediaItem post={post} />
      </div>
    </div>
  );
};

FacebookPost.propTypes = {
  post: PropTypes.shape({
    link: PropTypes.string,
    picture: PropTypes.string,
    full_picture: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default FacebookPost;
