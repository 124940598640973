import React from 'react';
import { Segment } from 'semantic-ui-react';

import HighlightData from './HighlightData';

const TeaserHeroSidebar = (props) => {
  const { sidebarData } = props;

  const teaserHeroSidebarDataRender = sidebarData ? (
    <>{sidebarData(props)}</>
  ) : (
    <HighlightData {...props} />
  );

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>Highlight Block volle Breite</h2>
      </header>

      {teaserHeroSidebarDataRender}
    </Segment.Group>
  );
};

export default TeaserHeroSidebar;
