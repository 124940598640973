/**
 * DefaultContent view component.
 * @module components/theme/View/DefaultContent
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

/**
 * Document view component class.
 * @function DefaultContent
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const DefaultContent = ({ content }) => (
  <div className="view-wrapper">
    {content.title && (
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && <span> &mdash;{` ${content.subtitle}`}</span>}
      </h1>
    )}
    {content.description && (
      <p className="description">{content.description}</p>
    )}
    {content.image && (
      <div className="documentImage">
        <Image
          alt={content.title}
          title={content.title}
          src={
            content.image['content-type'] === 'image/svg+xml'
              ? content.image.download
              : content.image.scales.big.download
          }
        />
      </div>
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultContent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default DefaultContent;
