/**
 * Themenwelten homepage element.
 * @module components/theme/Homepage/Themenwelten
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ThemenweltenAlbum from '../View/Albums/ThemenweltenAlbum';
import { getHomePageThemenwelten } from '../../actions';

/**
 * ThemenweltenView view component class.
 * @class ThemenweltenView
 * @extends Component
 */
class ThemenweltenSnippet extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          url: PropTypes.string,
          image: PropTypes.object,
          '@type': PropTypes.string,
        }),
      ),
    }).isRequired,
    getHomePageThemenwelten: PropTypes.func.isRequired,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getHomePageThemenwelten();
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const items = this.props.content.items.slice(0, 3);
    return (
      <section className="themenwelten-list">
        <Container>
          <div className="title">
            <div className="dottedline" />
            <h2>THEMENWELTEN</h2>
          </div>
          <p>
            Hier finden Sie wichtige Informationen, Hinweise und Tipps, die Sie
            bei Ihrer Begleitung von Geflüchteten unterstützen sollen!
          </p>
          <ThemenweltenAlbum
            items={items}
            tiled={false}
            showDescription={false}
          />
          <div style={{ textAlign: 'center' }}>
            <Button
              className="branded blue"
              as={Link}
              to="/themenwelten"
              size="big"
            >
              Weitere Themenwelten Entdecken
            </Button>
          </div>
        </Container>
      </section>
    );
  }
}

export default connect(
  (state) => ({
    error: state.homepageThemenwelten.error,
    loading: state.homepageThemenwelten.loading,
    loaded: state.homepageThemenwelten.loaded,
    content: state.homepageThemenwelten.content,
  }),
  (dispatch) => bindActionCreators({ getHomePageThemenwelten }, dispatch),
)(ThemenweltenSnippet);
