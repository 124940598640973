import React from 'react';
import { TextBody } from '@kitconcept/volto-blocks/components';

const Edit = (props) => {
  const { data, onChangeBlock, block } = props;
  return (
    <div className="block text centered">
      <TextBody
        data={data}
        block={block}
        onChangeBlock={onChangeBlock}
        dataName="text"
        isEditMode
      />
    </div>
  );
};

export default Edit;
