/**
 * Homepage sprachmodule actions.
 * @module actions/homepage/sprachmodule
 */

import { GET_HOMEPAGE_NACHRICHTEN } from '../../constants/ActionTypes';

/**
 * Get Homepage nachrichten items.
 * @function getHomePageNachrichten
 * @returns {Object} Homepage nachrichten action.
 */
export default function getHomePageNachrichten() {
  return {
    type: GET_HOMEPAGE_NACHRICHTEN,
    request: {
      op: 'get',
      path:
        '/nachrichten/@search?portal_type=News+Item&sort_on=created&sort_order=descending&fullobjects',
    },
  };
}
