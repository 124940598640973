import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import { MultiSourceWidget } from '@kitconcept/volto-blocks/components';
import { CheckboxWidget, TextWidget } from '@plone/volto/components';

const SliderData = (props) => {
  return (
    <>
      <Segment basic className="form sidebar-slider-data">
        <CheckboxWidget
          id="automatic-slides"
          title="Slides automatisch wechseln"
          value={props.data.enable_automatic_slides}
          onChange={(event, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              enable_automatic_slides: value,
            });
          }}
        />
        <TextWidget
          id="slide-duration"
          title="Dauer"
          description="Dauer pro angezeigtem Slide (in Sekunden)"
          required={false}
          value={props.data.slide_duration}
          onChange={(event, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              slide_duration: value,
            });
          }}
        />
      </Segment>
      <MultiSourceWidget {...props} />
    </>
  );
};

SliderData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default SliderData;
