import React from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Toast } from '@plone/volto/components';
import { getUser } from '@plone/volto/actions';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

export function getCompletedProfileStatus(user) {
  let score = 0;
  if (user.username && user.fullname) {
    score++;
  }
  if (user.portrait) score++;
  if (user.stadt) score++;
  if (
    user.searching_for_support ||
    user.involved_question ||
    user.i_can_help_with ||
    user.home_page ||
    user.hashtags ||
    user.description ||
    user.subscribed_to_newsletter
  ) {
    score++;
  }
  return score;
}

const CompleteProfileToast = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );
  const user = useSelector((state) => state.users.user);

  React.useEffect(() => {
    if (isEmpty(user) && userId) dispatch(getUser(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  React.useEffect(() => {
    if (!isEmpty(user)) {
      const score = getCompletedProfileStatus(user);
      if (score < 4) {
        if (!toast.isActive('profile-completion')) {
          toast.success(
            <Link to="/mein-profil">
              <Toast
                success
                title={`Ihr Profil ist zu ${score * 25}% Prozent ausgefüllt.`}
                content={
                  <>
                    Hier können Sie die restlichen Angaben ergänzen und Ihr
                    Profil vervollständigen.
                  </>
                }
              />
            </Link>,
            { autoClose: false, toastId: 'profile-completion' },
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <></>;
};

export default CompleteProfileToast;
