/**
 * Sprachmodule homepage element.
 * @module components/theme/Homepage/Sprachmodule
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Container } from 'semantic-ui-react';

import { getHomePageSprachmodule } from '../../actions';
import SprachModuleAlbum from '../View/Albums/SprachModuleAlbum';

/**
 * SprachmoduleView view component class.
 * @class SprachmoduleView
 * @extends Component
 */
class SprachmoduleSnippet extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          url: PropTypes.string,
          image: PropTypes.object,
          '@type': PropTypes.string,
        }),
      ),
    }).isRequired,
    getHomePageSprachmodule: PropTypes.func.isRequired,
  };

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  componentWillMount() {
    this.props.getHomePageSprachmodule();
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const items = this.props.content.items.slice(0, 3);
    return (
      <section className="sprachmodule-list">
        <Container>
          <div className="title">
            <div className="dottedline" />
            <h2>SPRACHMODULE</h2>
          </div>
          <p>
            Hier finden Sie fertige Lerneinheiten zum Ausdrucken, die Sie bei
            der Sprachbegleitung einsetzen können!
          </p>
          <SprachModuleAlbum
            items={items}
            tiled={false}
            showDescription={false}
          />
          <div style={{ textAlign: 'center' }}>
            <Button
              className="branded blue"
              as={Link}
              to="/sprachmodule"
              size="big"
            >
              Weitere Sprachmodule Entdecken
            </Button>
          </div>
        </Container>
      </section>
    );
  }
}

export default connect(
  (state) => ({
    error: state.homepageSprachmodule.error,
    loading: state.homepageSprachmodule.loading,
    loaded: state.homepageSprachmodule.loaded,
    content: state.homepageSprachmodule.content,
  }),
  (dispatch) => bindActionCreators({ getHomePageSprachmodule }, dispatch),
)(SprachmoduleSnippet);
