/**
 * Homepage Themenwelten actions.
 * @module actions/homepage/themenwelten
 */

import { GET_HOMEPAGE_THEMENWELTEN } from '../../constants/ActionTypes';

/**
 * Get Homepage Themenwelten.
 * @function getHomePageThemenwelten
 * @returns {Object} Homepage Themenwelten action.
 */
export default function getHomePageThemenwelten() {
  return {
    type: GET_HOMEPAGE_THEMENWELTEN,
    request: {
      op: 'get',
      path: '/themenwelten?fullobjects',
    },
  };
}
