/**
 * DefaultContent view component.
 * @module components/theme/View/DefaultContent
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Document view component class.
 * @function DefaultContent
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const DefaultContent = ({ content }) => (
  <div className="view-wrapper">
    {(content.title || content.description) && (
      <header>
        {content.title && (
          <h1 className="documentFirstHeading">{content.title}</h1>
        )}
        {content.description && (
          <p className="description">{content.description}</p>
        )}
      </header>
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultContent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default DefaultContent;
