/**
 * Comments components.
 * @module components/theme/Comments/Comments
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import 'moment/locale/de';
import { Button, Container, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import commentsSVG from '../../../../../components/Comments/comments.svg';
import bullhornSVG from '../../../../../components/Comments/speaker.svg';

import { addComment, deleteComment, listComments } from '@plone/volto/actions';
import { getBaseUrl } from '@plone/volto/helpers';
import { CommentEditModal, Form } from '@plone/volto/components';
import Comment from './Comment';

const messages = defineMessages({
  comment: {
    id: 'Comment',
    defaultMessage: 'Comment',
  },
  commentDescription: {
    id:
      'You can add a comment by filling out the form below. Plain text formatting.',
    defaultMessage:
      'You can add a comment by filling out the form below. Plain text formatting.',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
});

/**
 * Comments container class.
 * @class Comments
 * @extends Component
 */
class Comments extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    addComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    items_total: PropTypes.number,
    listComments: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        author_name: PropTypes.string,
        comment_task_title: PropTypes.string,
        comment_task_text: PropTypes.string,
        creation_date: PropTypes.string,
        text: PropTypes.shape({
          data: PropTypes.string,
          'mime-type': PropTypes.string,
        }),
        is_deletable: PropTypes.bool,
        is_editable: PropTypes.bool,
      }),
    ).isRequired,
    addRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    deleteRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    token: PropTypes.string,
  };

  /**
   * Property types.
   * @property {Object} defaultProps Property types.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Comments
   */
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onEditOk = this.onEditOk.bind(this);
    this.onEditCancel = this.onEditCancel.bind(this);
    this.state = {
      showEdit: false,
      editId: null,
      editText: null,
      cardActive: false,
    };
  }

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  UNSAFE_componentWillMount() {
    this.props.listComments(getBaseUrl(this.props.pathname));
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.pathname !== this.props.pathname ||
      (this.props.addRequest.loading && nextProps.addRequest.loaded) ||
      (this.props.deleteRequest.loading && nextProps.deleteRequest.loaded)
    ) {
      this.props.listComments(getBaseUrl(nextProps.pathname));
    }
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {Object} formData Form data.
   * @returns {undefined}
   */
  onSubmit(formData) {
    this.props.addComment(getBaseUrl(this.props.pathname), formData.comment);
  }

  /**
   * Delete handler
   * @method onDelete
   * @param {Object} event Event object.
   * @param {string} value Delete value.
   * @returns {undefined}
   */
  onDelete(event, { value }) {
    this.props.deleteComment(value);
  }

  /**
   * Edit handler
   * @method onEdit
   * @param {Object} event Event object.
   * @param {string} value Delete value.
   * @returns {undefined}
   */
  onEdit(event, { value }) {
    this.setState({
      showEdit: true,
      editId: value.id,
      editText: value.text,
    });
  }

  /**
   * On edit ok
   * @method onEditOk
   * @returns {undefined}
   */
  onEditOk() {
    this.setState({
      showEdit: false,
      editId: null,
      editText: null,
    });
    this.props.listComments(getBaseUrl(this.props.pathname));
  }

  /**
   * On edit cancel
   * @method onEditCancel
   * @returns {undefined}
   */
  onEditCancel() {
    this.setState({
      showEdit: false,
      editId: null,
      editText: null,
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container className="comments">
        <div id="comments" className="comments">
          <div className="commentTaskSection">
            <h2 className="comments-seperator">
              {' '}
              <i className="icon">
                {' '}
                <img src={commentsSVG} alt="commentsSVG" />
              </i>{' '}
              {this.props.items_total} Kommentare
            </h2>
            {this.props.content?.comment_task_title ? (
              <div>
                <div className="commentTask">
                  <div className="discuss">
                    <i className="icon">
                      {' '}
                      <img src={bullhornSVG} alt="commentsSVG" />
                    </i>{' '}
                  </div>
                </div>
                <div className="commentBox">
                  <h3>{this.props.content.comment_task_title}</h3>
                  <p>{this.props.content.comment_task_text}</p>
                </div>
              </div>
            ) : (
              this.props.content?.comment_task_text && (
                <div>
                  <div className="commentTask">
                    <div className="discuss">
                      <i className="icon">
                        {' '}
                        <img src={bullhornSVG} alt="commentsSVG" />
                      </i>{' '}
                    </div>
                  </div>
                  <div className="commentBox">
                    <h3>{this.props.content.comment_task_title}</h3>
                    <p>{this.props.content.comment_task_text}</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        {this.props.token && this.props.items_total >= 1 && (
          <Form
            onSubmit={this.onSubmit}
            submitLabel={this.props.intl.formatMessage(messages.comment)}
            resetAfterSubmit
            title="Kommentar"
            schema={{
              fieldsets: [
                {
                  fields: ['comment'],
                  id: 'default',
                  title: this.props.intl.formatMessage(messages.default),
                },
              ],
              properties: {
                comment: {
                  title: this.props.intl.formatMessage(messages.comment),
                  type: 'string',
                  widget: 'textarea',
                },
              },
              required: ['comment'],
            }}
          />
        )}
        <CommentEditModal
          open={this.state.showEdit}
          onCancel={this.onEditCancel}
          onOk={this.onEditOk}
          id={this.state.editId}
          text={this.state.editText}
        />
        {this.props?.items?.map((item) => (
          <Comment
            item={item}
            key={item['@id']}
            onDelete={this.onDelete}
            ariaLabel="Löschen"
          />
        ))}

        {this.props.token && (
          <Form
            onSubmit={this.onSubmit}
            submitLabel={this.props.intl.formatMessage(messages.comment)}
            resetAfterSubmit
            title={this.props.intl.formatMessage(messages.comment)}
            schema={{
              fieldsets: [
                {
                  fields: ['comment'],
                  id: 'default',
                  title: this.props.intl.formatMessage(messages.default),
                },
              ],
              properties: {
                comment: {
                  title: this.props.intl.formatMessage(messages.comment),
                  type: 'string',
                  widget: 'textarea',
                },
              },
              required: ['comment'],
            }}
          />
        )}
        {!this.props.token && (
          <Segment className="notoken" clearing>
            <Button as={Link} to="/login">
              Sie möchten einen Kommentar schreiben? Bitte melden Sie sich an
              oder registrieren Sie sich zunächst.
            </Button>
          </Segment>
        )}
      </Container>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.comments.items,
      items_total: state.comments.items_total,
      content: state.content.data,
      addRequest: state.comments.add,
      deleteRequest: state.comments.delete,
      token: state.userSession.token,
    }),
    { addComment, deleteComment, listComments },
  ),
)(Comments);
