import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

const MyProfileSaved = () => {
  return (
    <Container>
      <h1>Profil gespeichert</h1>
      <p>
        Vielen Dank für das Ausfüllen des Profils. Ihre Daten wurden erfolgreich
        gespeichert.
      </p>
      <p>
        <Link to="/">Hier geht´s zur Startseite</Link>.
      </p>
      <p>
        <a href="https://www.vhs-ehrenamtsportal.de/community">
          Hier geht´s zum Community-Bereich
        </a>
        .
      </p>
      <p>
        <a href="/austausch/engagementfinder">
          Hier geht´s zum Engagementfinder
        </a>
        .
      </p>
      <p>Wir wünschen Ihnen viel Freude mit unseren Inhalten.</p>
    </Container>
  );
};

export default MyProfileSaved;
