import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Message, Button } from 'semantic-ui-react';
// import { Icon, Statistic } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getTeaserImageURL } from './utils';

// import circleRed from './circle-red.svg';
// import circleYellow from './circle-yellow.svg';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const WithHeading = ({ data, dataBlock, isEditMode }) => {
  const intl = useIntl();
  return (
    <>
      {!data.href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.href && (
        <>
          {(() => {
            const item = (
              <div
                className={cx('grid-teaser-item top', {
                  withDescription: data?.description,
                })}
              >
                <div className="teaser-item-content">
                  {data?.preview_image && (
                    <div className="grid-image-wrapper">
                      <img
                        src={getTeaserImageURL(data)}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  )}
                  {/* {data?.cluster && (
                    <div className="center aligned themenwelt-pretitle">
                      <img
                        src={
                          data.cluster === 'Integration gestalten'
                            ? circleRed
                            : circleYellow
                        }
                        alt="circle"
                        title="circle"
                      />
                      <span>{data.cluster}</span>
                    </div>
                  )} */}
                  <h3>{data?.title}</h3>
                  {/* {data?.item_counts && (
                    <Statistic size="mini" horizontal className="item counts">
                      <Statistic.Value>
                        <Icon name="user" color="red" className="large" />
                        <span>
                          {data.item_counts.lerneinheit} Lerneinheiten
                        </span>
                      </Statistic.Value>
                      <Statistic.Value>
                        <Icon
                          name="unordered list"
                          color="yellow"
                          className="fontawesome large"
                        />{' '}
                        <span>
                          {data.item_counts.methodereference} Methoden
                        </span>
                      </Statistic.Value>
                      <Statistic.Value className="kommentare anzahl">
                        <Icon name="comments" color="green" className="big" />
                        <span>{data.item_counts.discussion_item}</span>
                      </Statistic.Value>
                    </Statistic>
                  )} */}
                  {!dataBlock.hide_description && data?.description && (
                    <p>{data?.description}</p>
                  )}
                </div>
                <Button className="branded blue" onClick={() => {}}>
                  {data.buttontext}
                </Button>
              </div>
            );

            if (!isEditMode) {
              return (
                <Link
                  to={flattenToAppURL(data.href)}
                  target={data.openLinkInNewTab ? '_blank' : null}
                >
                  {item}
                </Link>
              );
            } else {
              return item;
            }
          })()}
        </>
      )}
    </>
  );
};

WithHeading.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default WithHeading;
