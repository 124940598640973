import React from 'react';
import PropTypes from 'prop-types';
import imageCCBY from './by.png';
import imageCCBYSA from './by-sa.png';
import imageCCBYND from './by-nd.png';
import imageCCBYNC from './by-nc.eu.png';
import imageCCBYNCSA from './by-nc-sa.eu.png';
import imageCCBYNCND from './by-nc-nd.eu.png';
import imageCC0 from './cc0.png';

const getLicense = (license) => {
  switch (license.token) {
    case 'CC-BY':
      return ['https://creativecommons.org/licenses/by/4.0/deed.de', imageCCBY];
    case 'CC-BY-SA':
      return [
        'https://creativecommons.org/licenses/by-sa/4.0/deed.de',
        imageCCBYSA,
      ];
    case 'CC-BY-ND':
      return [
        'https://creativecommons.org/licenses/by-nd/4.0/deed.de',
        imageCCBYND,
      ];
    case 'CC-BY-NC':
      return [
        'https://creativecommons.org/licenses/by-nc/4.0/deed.de',
        imageCCBYNC,
      ];
    case 'CC-BY-NC-SA':
      return [
        'https://creativecommons.org/licenses/by-nc-sa/4.0/deed.de',
        imageCCBYNCSA,
      ];
    case 'CC-BY-NC-ND':
      return [
        'https://creativecommons.org/licenses/by-nc-nd/4.0/deed.de',
        imageCCBYNCND,
      ];
    case 'CC0':
      return [
        'https://creativecommons.org/publicdomain/zero/1.0/deed.de',
        imageCC0,
      ];
    default:
      return ['https://creativecommons.org/licenses/by/4.0/deed.de', imageCCBY];
  }
};

const License = ({ license }) => {
  const [url, image] = getLicense(license);
  return (
    <div className="license">
      <a href={url}>
        <img src={image} alt="license info" />
      </a>
    </div>
  );
};

License.propTypes = {
  license: PropTypes.string.isRequired,
};

export default License;
