/**
 * Nachrichten homepage element.
 * @module components/theme/Homepage/Nachrichten
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Container, Item } from 'semantic-ui-react';
import { getHomePageNachrichten } from '../../actions';

/**
 * NachrichtenView view component class.
 * @class NachrichtenView
 * @extends Component
 */
class NachrichtenSnippet extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          url: PropTypes.string,
          image: PropTypes.object,
          '@type': PropTypes.string,
        }),
      ),
    }).isRequired,
    getHomePageNachrichten: PropTypes.func.isRequired,
  };

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  componentWillMount() {
    this.props.getHomePageNachrichten();
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const content = { items: this.props.content.items.slice(0, 2) };
    return (
      <section className="nachrichten-list">
        <Container>
          <h2>Neues aus der Ehrenamtswelt</h2>
          <Item.Group relaxed>
            {content.items.map((item) => (
              <Fragment key={item.title}>
                <Link to={item.url}>
                  <h3>
                    <span>{item.title}</span>
                  </h3>
                </Link>
                <Item as={Link} to={item.url} key={item.url}>
                  {item.image ? (
                    <Item.Image size="small" src={item.image.download} />
                  ) : null}
                  <Item.Content>
                    <Item.Description content={item.description} />
                  </Item.Content>
                </Item>
              </Fragment>
            ))}
          </Item.Group>
        </Container>
      </section>
    );
  }
}

export default connect(
  (state) => ({
    error: state.homepageNachrichten.error,
    loading: state.homepageNachrichten.loading,
    loaded: state.homepageNachrichten.loaded,
    content: state.homepageNachrichten.content,
  }),
  (dispatch) => bindActionCreators({ getHomePageNachrichten }, dispatch),
)(NachrichtenSnippet);
