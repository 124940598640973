/**
 * Event form.
 * @module components/EventForm/EventForm
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import {
  Container,
  Button,
  Form,
  Input,
  Message,
  Segment,
  TextArea,
  Grid,
} from 'semantic-ui-react';

import { addMessage } from '@plone/volto/actions';
import { CheckboxWidget } from '@plone/volto/components';
import { emailNotification } from '../../actions';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  title: {
    id: 'Event form',
    defaultMessage: 'Event form',
  },
  description: {
    id: 'Fill in this form to contact the site owners.',
    defaultMessage: 'Fill in this form to contact the site owners.',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  nameDescription: {
    id: 'Please enter your full name.',
    defaultMessage: 'Please enter your full name.',
  },
  from: {
    id: 'From',
    defaultMessage: 'From',
  },
  fromDescription: {
    id: 'Please enter your e-mail address',
    defaultMessage: 'Please enter your e-mail address',
  },
  subject: {
    id: 'Subject',
    defaultMessage: 'Subject',
  },
  subjectDescription: {
    id: 'Please, enter a short summary of the message',
    defaultMessage: 'Please, enter a short summary of the message',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  messageDescription: {
    id: 'Please enter the message you want to send',
    defaultMessage: 'Please enter the message you want to send',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  messageandsenderrequired: {
    id: 'Both sender address and message are required',
    defaultMessage: 'Both sender address and message are required',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  successMessageSentTitle: {
    id: 'Info',
    defaultMessage: 'Info',
  },
  successMessageSentBody: {
    id:
      'A mail has now been sent to the site administrator regarding your questions and/or comments.',
    defaultMessage:
      'A mail has now been sent to the site administrator regarding your questions and/or comments.',
  },
  feedbackThanks: {
    id: 'Thank you for your feedback',
    defaultMessage: 'Thank you for your feedback',
  },
});

const EventList = [
  'Seminar 1: Alphabetisierung und Grundbildung unterstützen (23.09.2020, 10:30 – 12:00 Uhr)',
  'Seminar 2: Ehrenamtliche als Weiterbildungslotsen (07.10.2020, 10:30 – 12:00 Uhr)',
  'Seminar 3: Lesen im Tandem (10.11.2020, 14:00 – 15:30 Uhr)',
  'Seminar 4: Praxisbeispiel "Miteinander lernen" (18.11.2020, 10:30 – 12:00 Uhr)',
  'Seminar 5: Verbraucher schützen (26.11.2020, 16:00 – 17:30 Uhr)',
];

/**
 * Event form class.
 * @class ContactForm
 * @extends Component
 */
class EventForm extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    addMessage: PropTypes.func.isRequired,
    emailNotification: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContactForm
   */
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: null,
      isSuccessful: false,
      name: '',
      senderAddress: '',
      ehrenamtlich: false,
      hauptamtlich: false,
      organisation: '',
      selection: '',
      dataProtection: false,
      event1: false,
      event2: false,
      event3: false,
      event4: false,
      event5: false,
    };
  }

  /**
   * On submit handler
   * @method onSubmit
   * @param {Object} data object.
   * @returns {undefined}
   */
  onSubmit() {
    const {
      name,
      organisation,
      senderAddress,
      dataProtection,
      ehrenamtlich,
      hauptamtlich,
      aktiv,
      nichtAktiv,
      event1,
      event2,
      event3,
      event4,
      event5,
    } = this.state;

    const eventList = [];
    if (event1) {
      eventList.push(EventList[0]);
    }
    if (event2) {
      eventList.push(EventList[1]);
    }
    if (event3) {
      eventList.push(EventList[2]);
    }
    if (event4) {
      eventList.push(EventList[3]);
    }
    if (event5) {
      eventList.push(EventList[4]);
    }
    const subject = 'Anmeldung zur Online-Seminarreihe 2020';

    const message = `Name: ${name}\nOrganisation: ${organisation}\nE-Mail: ${senderAddress}\nEhrenamtlich: ${ehrenamtlich}\nHauptamtlich: ${hauptamtlich}\nAktiv: ${aktiv}\nNicht Aktiv: ${nichtAktiv}\nEvents: ${eventList}`;

    if (senderAddress && dataProtection) {
      this.props.emailNotification(senderAddress, message, name, subject);
      this.setState({
        error: null,
        isSuccessful: true,
      });
    } else {
      this.setState({
        error: {
          message: this.props.intl.formatMessage(
            messages.messageandsenderrequired,
          ),
        },
      });
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      name,
      senderAddress,
      organisation,
      dataProtection,
      aktiv,
      nichtAktiv,
      ehrenamtlich,
      hauptamtlich,
      event1,
      event2,
      event3,
      event4,
      event5,
    } = this.state;

    if (this.state.isSuccessful) {
      return (
        <header>
          <h1 className="documentFirstHeading">
            Sie haben sich erfolgreich angemeldet!
          </h1>
          <p className="description">
            Sie erhalten alle weiteren Informationen per E-Mail.{' '}
          </p>
          <br></br>
          <div style={{ textAlign: 'center' }}>
            <Button
              as={Link}
              to="/seminarreihe-2020"
              style={{
                backgroundColor: '#33537b',
                color: 'white',
                textTransform: 'uppercase',
                fontSize: '1.15rem',
                padding: '0.9em',
              }}
            >
              Zurück zur Seminarübersicht
            </Button>
          </div>
        </header>
      );
    }

    return (
      <Container id="page-event-form">
        <Helmet title={this.props.intl.formatMessage(messages.title)} />
        <h1 className="documentFirstHeading">
          Anmeldung Online-Seminarreihe 2020
        </h1>
        <Container>
          <Form method="post" onSubmit={this.onSubmit}>
            {this.state.error && (
              <Message
                icon="warning"
                negative
                attached
                header={this.props.intl.formatMessage(messages.error)}
                content={this.state.error.message}
              />
            )}

            <Segment basic>
              <h3>Bitte füllen Sie die unteren Felder aus.</h3>
              <Form.Field inline required>
                <Input
                  id="name"
                  name="name"
                  placeholder="Vor- und Nachname"
                  value={name}
                  onChange={this.handleChange}
                  required
                />
              </Form.Field>

              <div>
                <Form className="organisation">
                  <Form.Field required>
                    <Grid>
                      <Grid.Column width="4">
                        <p className="questions">
                          Sind Sie Ehrenamtlich oder Hauptamtlich aktiv?
                        </p>
                      </Grid.Column>
                      <Grid.Column width="3">
                        <CheckboxWidget
                          id="ehrenamtlich"
                          title="Ehrenamtlich"
                          value={ehrenamtlich}
                          onChange={(id, value) => {
                            this.handleChange('', {
                              name: 'ehrenamtlich',
                              value,
                            });
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column width="3">
                        <CheckboxWidget
                          id="ehrenamtlich"
                          title="Hauptamtlich"
                          value={hauptamtlich}
                          onChange={(id, value) => {
                            this.handleChange('', {
                              name: 'hauptamtlich',
                              value,
                            });
                          }}
                        />
                      </Grid.Column>
                    </Grid>
                  </Form.Field>
                </Form>
              </div>

              <Form>
                <Form.Field inline required>
                  <Input
                    required
                    id="senderAddress"
                    name="senderAddress"
                    placeholder="E-Mail"
                    value={senderAddress}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </Form>

              <Form>
                <Form.Field inline>
                  <TextArea
                    id="organisation"
                    name="organisation"
                    placeholder="Organisation"
                    value={organisation}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </Form>

              <Form className="aktiv">
                <Form.Field required>
                  <Grid>
                    <Grid.Column width="4">
                      <p className="questions">
                        Schon im Bereich der Grundbildung und Alphabetisierung
                        aktiv?
                      </p>
                    </Grid.Column>
                    <Grid.Column width="3">
                      <CheckboxWidget
                        id="aktiv"
                        title="Ja"
                        value={aktiv}
                        onChange={(id, value) => {
                          this.handleChange('', {
                            name: 'aktiv',
                            value,
                          });
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width="3">
                      <CheckboxWidget
                        id="aktiv"
                        title="Nein"
                        value={nichtAktiv}
                        onChange={(id, value) => {
                          this.handleChange('', {
                            name: 'nichtAktiv',
                            value,
                          });
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </Form.Field>
              </Form>

              <p style={{ marginTop: '50px' }}>
                Ich möchte mich zu den folgenden Seminaren anmelden
                (Mehrfachauswahl möglich):
              </p>

              <Segment basic className="events">
                <span style={{ fontSize: '16px' }}>Bitte auswählen</span>
                <Form className="events">
                  <CheckboxWidget
                    id="external"
                    title="Seminar 1: Alphabetisierung und Grundbildung unterstützen (23.09.2020, 10:30 – 12:00 Uhr)"
                    value={event1}
                    onChange={(id, value) => {
                      this.handleChange('', { name: 'event1', value });
                    }}
                  />

                  <CheckboxWidget
                    id="external"
                    title="Seminar 2: Ehrenamtliche als Weiterbildungslotsen (07.10.2020, 10:30 – 12:00 Uhr)"
                    value={event2}
                    onChange={(id, value) => {
                      this.handleChange('', { name: 'event2', value });
                    }}
                  />

                  <CheckboxWidget
                    id="external"
                    title="Seminar 3: Praxisbeispiel 'Miteinander lernen' (18.11.2020, 10:30 – 12:00 Uhr)"
                    value={event3}
                    onChange={(id, value) => {
                      this.handleChange('', { name: 'event3', value });
                    }}
                  />

                  <CheckboxWidget
                    id="external"
                    title="Seminar 4:  Lesen im Tandem (10.11.2020, 14:00 – 15:30 Uhr)"
                    value={event4}
                    onChange={(id, value) => {
                      this.handleChange('', { name: 'event4', value });
                    }}
                  />

                  <CheckboxWidget
                    id="external"
                    title="Seminar 5: Verbraucher schützen (26.11.2020, 16:00 – 17:30 Uhr)"
                    value={event5}
                    onChange={(id, value) => {
                      this.handleChange('', { name: 'event5', value });
                    }}
                  />
                </Form>
              </Segment>

              <Form className="data-protection">
                <h3>Zustimmung Datenschutz</h3>
                <Grid>
                  <Grid.Column width="1">
                    <CheckboxWidget
                      id="data-protection"
                      value={dataProtection}
                      onChange={(id, value) => {
                        this.handleChange('', {
                          name: 'dataProtection',
                          value,
                        });
                      }}
                      required
                    />
                  </Grid.Column>
                  <Grid.Column width="11">
                    <p>
                      {' '}
                      Ich willige ein, dass meine in das Formular eingetragenen
                      Daten vom DVV gespeichert und genutzt werden dürfen, um
                      meine Bestellung zu bearbeiten und mich über Neuerungen zu
                      dem Thema zu informieren. Eine Weitergabe an Dritte
                      erfolgt nicht. Ich kann die Einwilligung jederzeit per
                      E-Mail an ehrenamtsportal@dvv-vhs.de oder per Brief an
                      Deutscher Volkshochschul-Verband e.V. widerrufen. Weitere
                      Informationen, auch zu Ihren Rechten zum Widerruf der
                      Einwilligung und zur Löschung der Daten, finden Sie in der{' '}
                      <b>
                        <a href="/datenschutz">Datenschutzerklärung</a>
                      </b>
                      .
                    </p>
                  </Grid.Column>
                </Grid>
              </Form>
            </Segment>

            <Segment basic style={{ textAlign: 'center' }}>
              <Button
                type="submit"
                size="big"
                style={{
                  textTransform: 'uppercase',
                  backgroundColor: 'rgb(175,200,5)',
                  color: 'white',
                }}
              >
                Anmelden
              </Button>
            </Segment>
          </Form>
        </Container>
      </Container>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      loading: state.emailNotification.loading,
      loaded: state.emailNotification.loaded,
      error: state.emailNotification.error,
    }),
    (dispatch) =>
      bindActionCreators({ addMessage, emailNotification }, dispatch),
  ),
)(EventForm);
