/**
 * OVERRIDE navigation.js
 * REASON: Handle blank links
 * FILE: https://github.com/plone/volto/blob/6cb3cfab77e9ddc9e32dc29dd45652881db91542/src/reducers/navigation/navigation.js
 * FILE VERSION: Volto 16.2.0
 * DATE: 2022-05-18
 * DEVELOPER: @steffenri
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * Navigation reducer.
 * @module reducers/navigation/navigation
 */

import { map } from 'lodash';
import { flattenToAppURL } from '@plone/volto/helpers';

import { GET_NAVIGATION } from '@plone/volto/constants/ActionTypes';

// START CUSTOMIZATION
import config from '@plone/volto/registry';
// END CUSTOMIZATION

const initialState = {
  error: null,
  items: [],
  loaded: false,
  loading: false,
};

/**
 * Recursive function that process the items returned by the navigation
 * endpoint
 * @function getRecursiveItems
 * @param {array} items The items inside a navigation response.
 * @returns {*} The navigation items object (recursive)
 */
function getRecursiveItems(items) {
  return map(items, (item) => ({
    title: item.title,
    description: item.description,
    url: flattenToAppURL(item['@id']),
    ...(item.items && { items: getRecursiveItems(item.items) }),
    // START CUSTOMIZATION
    blank_link: item?.blank_link ? item.blank_link : false,
    remoteUrl: item?.remoteUrl
      ? item.remoteUrl.replace(config.settings.apiPath, '')
      : '',
    ...(item.items && { items: getRecursiveItems(item.items) }),
    // END CUSTOMIZATION
  }));
}

/**
 * Navigation reducer.
 * @function navigation
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function navigation(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_NAVIGATION}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_NAVIGATION}_SUCCESS`:
      return {
        ...state,
        error: null,
        items: getRecursiveItems(action.result.items),
        loaded: true,
        loading: false,
      };
    case `${GET_NAVIGATION}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
